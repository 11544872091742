import React from "react";
import { Col, Row } from "react-bootstrap";

import TouchedItem from "./TouchedItem";
import useCollection from "../../Hooks/useCollection";
import classes from "./MidasTouch.module.css";
import { useRecoilValue } from "recoil";
import { accountState } from "../../services/atoms";

const TouchedNfts = () => {
  const account = useRecoilValue(accountState);
  const { documents, error, success } = useCollection("midas", [
    "owner",
    "==",
    account.toLowerCase(),
  ]);

  return (
    <>
      {" "}
      <Row className=" d-none d-md-flex justify-content-between my-1 rounded p-2 border-1 align-items-center w-100 mt-3">
        {!error && documents && documents.length !== 0 && (
          <>
            {" "}
            <Col sm="2">
              <p className="d-block m-0 p-0">NFT</p>
            </Col>
            <Col sm="2">
              <p className="d-block m-0 p-0">Contract</p>
            </Col>
            <Col sm="2">
              <p className="d-block m-0 p-0">Value</p>
            </Col>
            <Col sm="2"></Col>
            <Col sm="2"></Col>
          </>
        )}
      </Row>
      {!error &&
        documents &&
        documents.length !== 0 &&
        documents.map((item) => {
          return <TouchedItem {...item} key={item.id} />;
        })}
      <Row
        className={`${classes["loader-container"]} d-flex justify-content-center align-items-center`}
      >
        {error && (
          <p className="text-center text-danger ">
            Sorry, something went wrong we can't get your NFTs please try later
          </p>
        )}
        {!error && documents && documents.length === 0 && (
          <p className="text-center text-white ">Empty</p>
        )}
      </Row>
    </>
  );
};

export default TouchedNfts;
