import React from "react";
import fixNum from "../../utils/FixNumbers";
const GoldPrice = ({ price }) => {
  return (
    <div className="card p-2 me-2">{`Gold live price (1g) $${parseFloat(
      price
    ).toFixed(fixNum(parseFloat(price)))} `}</div>
  );
};

export default GoldPrice;
