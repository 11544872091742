import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const MessageModal = (props) => {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate("/wallet");
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="d-flex justify-content-center align-items-center flex-column pb-1">
        <p>Congratulation !</p><br />
        <p>Your purchase is successfully completed !</p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center align-items-center pt-1">
        <button className="btnConn me-2" onClick={props.onHide}>
          Close
        </button>
        <button className="btnConn" onClick={clickHandler}>
          My Wallet
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageModal;
