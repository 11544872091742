import React from "react";
import { Button } from "react-bootstrap";
import { useRecoilState, useSetRecoilState } from "recoil";
import { midasFilterTermState } from "../../services/atoms";
import classes from "./MidasTouch.module.css";

const MidasFilter = () => {
  const [filterTerm, setFilterTerm] = useRecoilState(midasFilterTermState);
  const clickHandler = (e) => {
    setFilterTerm(e.currentTarget.dataset.filter);
  };
  return (
    <div className="d-flex justify-content-center align-items-center my-3">
      <Button
        data-filter={"all"}
        className={filterTerm === "all" ? `${classes.active} me-4` : "me-4"}
        onClick={clickHandler}
      >
        NFTs
      </Button>
      <Button
        data-filter={"touched"}
        onClick={clickHandler}
        className={filterTerm === "touched" ? `${classes.active} me-4` : "me-4"}
      >
        Touched NFTs
      </Button>
    </div>
  );
};

export default MidasFilter;
