import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Image, ListGroup, Card, Button } from "react-bootstrap";
// import Rating from '../components/Rating'
//import products from '../products'
import axios from "axios";
import useHttp from "../Hooks/useHttp";
import { AppParams, MarketPlaceClient, TokensClient } from "../config";
import { Network, Alchemy } from "alchemy-sdk";
import { MarketplaceAbi } from "../artifacts/contracts-abis/Marketplace-abi";
import BuyNFT from "../components/BuyNFT";
import { marketplaceItemById, myTokenDetails } from "../services/graphql";
import collection from "../data/nftCollection.json";
import { useCallback } from "react";
import Loader from "../UI/Loader";
import { accountState, goldPriceState } from "../services/atoms";
import { useRecoilValue } from "recoil";
import BigNumber from "bignumber.js";
import useGetGoldPrice from "../Hooks/useGetGoldPrice";
import fixNum from "../utils/FixNumbers";

const NFTDetails = () => {
  const { token_id, item_id, contractAddress } = useParams();
  const goldPrice = useRecoilValue(goldPriceState);
  const [fetchingState, setFetchingState] = useState({
    isLoading: false,
    success: false,
    error: "",
  });
  const [nft, setNft] = useState({});
  const account = useRecoilValue(accountState);
  const getMetadata = useCallback(async () => {
    try {
      setFetchingState((prevState) => {
        return { ...prevState, isLoading: true, error: "", success: false };
      });
      const settings = {
        apiKey: "I3HH-eMusjU1Vj2LgCDjFrQtUoYnsonO", // Replace with your Alchemy API Key.
        network: Network.ETH_GOERLI, // Replace with your network.
      };

      const alchemy = new Alchemy(settings);

      // Print NFT metadata returned in the response:
      const data = await alchemy.nft.getNftMetadata(
        AppParams.NFT_CONTRACT_ADDRESS,
        token_id
      );
      const owner = await alchemy.nft.getOwnersForNft(
        AppParams.NFT_CONTRACT_ADDRESS,
        token_id
      );
      setNft({
        imageUrl: data.rawMetadata.imageURL,
        categoryName: data.rawMetadata.categoryName,
        value: parseFloat(data.rawMetadata.categoryName),
        tokenUri: data.tokenUri,
        owner: owner.owners[0],
      });

      setFetchingState((prevState) => {
        return { ...prevState, isLoading: false, success: true };
      });
    } catch (error) {
      setFetchingState((prevState) => {
        return {
          ...prevState,
          isLoading: false,
          error: "Something went wrong we can't get NFT details",
          success: false,
        };
      });
    }
  }, [token_id]);
  useEffect(() => {
    getMetadata();
  }, [getMetadata]);

  if (fetchingState.isLoading) {
    return (
      <section className=" d-flex justify-content-center align-items-center w-100 min-vh-100">
        <Loader width={"26"} height={"26"} />
      </section>
    );
  }
  if (fetchingState.error) {
    return (
      <section className=" d-flex justify-content-center align-items-center w-100 min-vh-100">
        <p className="text-danger">{fetchingState.error}</p>
      </section>
    );
  }
  return (
    <div className="screenD container">
      {fetchingState.success && (
        <>
          <Link className="btn btn-outline-primary my-4 pl-0" to="/nfts">
            <i className="fa-solid fa-angle-left"></i> Back
          </Link>
          <Row>
            <Col>
              <span
                className="codeD"
                style={{ cursor: "pointer" }}
                // onClick={showOnExplorer}
              >
                #{token_id}
              </span>
            </Col>
          </Row>
          <Card.Text as="h3">{nft?.categoryName}</Card.Text>
          <Row className="rowD">
            <Col md={6} style={{ zIndex: 1 }}>
              {/* <Card.Text as='h3'>{nft?.itemId}</Card.Text> */}

              <div className="p25">
                <Image
                  src={nft.imageUrl}
                  alt={nft.categoryName}
                  style={{ borderRadius: 20 }}
                 className="sizeID"
                />
              </div>
            </Col>
            <Col md={6} className="colS">
              <div>
                <h4>Price</h4>
                <ListGroup variant="flush" className="card cardab">
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <div className="priceD">
                          {goldPrice.success && (
                            <div>
                              {`${new BigNumber(nft.value.toString())
                                .multipliedBy(goldPrice.value.toString())
                                .toNumber()
                                .toFixed(
                                  fixNum(
                                    new BigNumber(
                                      nft.value.toString()
                                    ).multipliedBy(goldPrice.value.toString())
                                  )
                                )}$`}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </div>
              <div>
                <h4>About</h4>
                <ListGroup variant="flush" className="card cardab">
                  <ListGroup.Item className="priceD">
                    Contract{" "}
                    <a
                      href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}`}
                      target="_blank"
                      className="itemS"
                      rel="noreferrer"
                    >
                      <span style={{ color: "#BAFF85 !important" }}>
                        {contractAddress ? contractAddress : "--"}
                      </span>
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item className="priceD">
                    Token ID{" "}
                    <a
                      href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${token_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span style={{ color: "#BAFF85 !important" }}>
                        {token_id}
                      </span>
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item className="priceD">
                    Owner{" "}
                    <a
                      href={`${AppParams.BLOCK_EXPLORER_URL}token/${contractAddress}?a=${account}`}
                      target="_blank"
                      className="itemS"
                      rel="noreferrer"
                    >
                      <span style={{ color: "#BAFF85 !important" }}>
                        {nft.owner ? nft.owner : ""}
                      </span>
                    </a>
                  </ListGroup.Item>

                  <ListGroup.Item className="priceD">
                    Class{" "}
                    <span>{nft.categoryName ? nft.categoryName : "--"}</span>{" "}
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mt-5">
                <h4>More</h4>
                <ListGroup variant="flush" className="card cardab">
                  <ListGroup.Item className="priceD">
                    Token URI{" "}
                    <a
                      href={nft.tokenUri.raw.gateway}
                      target="_blank"
                      className="itemS"
                      rel="noreferrer"
                    >
                      <span style={{ color: "#BAFF85 !important" }}>
                        {nft.tokenUri.raw}
                      </span>
                    </a>
                  </ListGroup.Item>

                  {/* <ListGroup.Item className="priceD">
                    Project info{" "}
                    <a
                      href={"#"}
                      className="itemS"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span style={{ color: "#BAFF85 !important" }}>
                        {item?.pdfFile}
                      </span>
                    </a>
                  </ListGroup.Item> */}
                  {/*<div style={{ textAlign: 'center' }}>
                <Button className='download-pdf m-3' variant='primary' onClick={(e) => { downloadURI(item?.pdfFile.replace("ipfs://", AppParams.IPFS_GATWAY), item?.category) }}>
                  Download PDF
  </Button>
              </div>*/}
                  <ListGroup.Item>
                    {/* <div style={{ textAlign: "center" }}>Description</div>{" "}
                    <br />{" "} */}
                    {/* <div className="spandesc">
                      <span>
                        {item?.description.includes("$100")
                          ? item?.description
                              .replace("$100", "1")
                              .replace(".", "") + " AVAX."
                          : item?.description}
                      </span>
                    </div>{" "} */}
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default NFTDetails;
