import { useState } from "react";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { Row, Col } from "react-bootstrap";

import { Pagination } from "antd";

import NFTCard from "./NFTCard";

import React from "react";

import categories from "../data/categories.json";
import BuyERC20 from "./BuyERC20";
import { AppParams } from "../config";

const Nfts = () => {
  const [nfts, setNfts] = useState([]);
  const [loadingState, setLoadingState] = useState("not-loaded");
  const [page, setPage] = useState(0);

  const paginationChanged = (pageNumber, pageSize) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(pageNumber - 1);
  };

  if (loadingState === "loaded" && !nfts.length)
    return <h1 className="px-20 py-10 text-3xl">No items in marketplace</h1>;
  return (
    <div className="w-100">
      <BuyERC20 />

      <Row>
        <Col
          sm={12}
          // md={9} lg={9} xl={10}
        >
          <div className="w-100 d-flex justify-content-center align-items-center mt-4">
            <h2>
              {" "}
              <a
                href={`https://goerli.etherscan.io/address/${AppParams.NFT_CONTRACT_ADDRESS}
          `}
                className="text-decortion-none sublink"
                rel="noreferrer"
                target={"_blank"}
              >
                {" "}
                GHOLD NFT Collection
              </a>{" "}
            </h2>
          </div>
          <div className="test2">
            <Row>
              {categories.map((category, index) => (
                <Col key={index} sm={12} md={4} lg={4} xl={4}>
                  <NFTCard {...category} />
                </Col>
              ))}
            </Row>
            <div>
              <Pagination
                onChange={paginationChanged}
                current={page + 1}
                defaultCurrent={page + 1}
                total={"1"}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Nfts;
