import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ethers } from 'ethers'
import { create as ipfsHttpClient } from 'ipfs-http-client'

import Web3Modal from 'web3modal'



import {
    Button,
    Form
} from 'react-bootstrap'
import { MainTokenAbi } from '../artifacts/contracts-abis/MainToken-abi'
import GenerateNFTsMetas from '../components/GenerateNFTsMetas'
import { AppParams } from '../config'

const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0')

const BatchCreateItems = () => {
    const navigate = useNavigate()

    const [fileUrl, setFileUrl] = useState(null)
    const [formInput, updateFormInput] = useState({
        //main seller: '0x50a5b931c6E1B824C5d18791524B8FdF63aE894f',
        //test seller: 0xE6585C6103f0972F408529b9C1452Ac0751b5699,
        seller: '0x4b7b08F11d201e9dcE30B5Be7AC56D312A398E73',
        yield: '1',
        tokenId: '',
        name: '',
        description: '',
        category: 'O2SLAUNCH',
        mintFrom:0,
        mintTo:0,
        tokensNumberToMint:3,
    })

    const [metas, setMetas] = useState([]);
    const [images, setImages] = useState([]);
    const [finalZipFile, setFinalZipFile] = useState(null);
    const [merged, setMerged] = useState([])
    const [lostMerge, setLostMerge] = useState([])


    useEffect(() => {
        if(formInput.tokensNumberToMint == null || formInput.mintFrom == null) return;
        let toValue = (parseInt(formInput.mintFrom) + parseInt(formInput.tokensNumberToMint))-1
        updateFormInput({ ...formInput, mintTo:toValue })
    }, [formInput.tokensNumberToMint,formInput.mintFrom])
    

    async function mergeFiles(e) {


        const files = e.target.files

        let firstFile;

        for (var i = 0; i < files.length; i++) {
            var reader = new FileReader();
            reader.fileName = files[i].name;
            reader.onload = onReaderLoad;
            reader.readAsText(files[i])
        }

        function compare(a, b) {
            if (parseFloat(a.Name.replace(".jpg", "")) < parseFloat(b.Name.replace(".jpg", ""))) {
                return -1;
            }
            if (parseFloat(a.Name.replace(".jpg", "")) > parseFloat(b.Name.replace(".jpg", ""))) {
                return 1;
            }
            return 0;
        }
        function compareLost(a, b) {
            if ((a) < (b)) {
                return -1;
            }
            if ((a) > (b)) {
                return 1;
            }
            return 0;
        }


        function zipMerged(metasToZip) {
            const zip = require('jszip')();
            let files = metasToZip
            zip.file("file.json", "{'Data':{'/':{'bytes':'CAE'}},'Links':" + JSON.stringify(metasToZip) + "}");
            zip.generateAsync({ type: "blob" }).then(content => {
                //saveAs(content, "example.zip");
                setFinalZipFile(content)
                console.log("ziped", content)
                const element = document.createElement("a");
                element.href = URL.createObjectURL(content);
                element.download = "myMergedFile.zip";
                document.body.appendChild(element);
                element.click();
            });
        }


        setTimeout(() => {
            //setLostMerge(lostMerge.sort(compareLost))
            //console.log("metas content merged", lostMerge.toString());

            setMetas(merged.sort(compare))
            console.log("metas content merged", merged);
            zipMerged(merged)
        }, 10000);

        //reader.readAsText(files[0]);

        function onReaderLoad(event) {
            //console.log(event.target.fileName)
            var obj = JSON.parse(event.target.result);
            var temp = merged;
            var tempLost = lostMerge;

            obj.Links.forEach(element => {
                if (element.Name.includes(".json")) {
                    element.Name = element.Name.slice(2);
                    temp.push(element)
                } else {
                    tempLost.push(element.Hash['/'])
                    //tempLost = tempLost+","+element.Hash['/']
                    //console.log("xxx",tempLost)
                }
            });

            setMerged(temp)
            setLostMerge(tempLost)
            //console.log("metas content merged", merged);
        }
    }
    async function uploadFiles(e) {


        const files = e.target.files


        for (var i = 0; i < files.length; i++) {
            var reader = new FileReader();
            reader.onload = onReaderLoad;
            reader.readAsText(files[i])
        }

        //reader.readAsText(files[0]);

        function onReaderLoad(event) {
            var obj = JSON.parse(event.target.result);
            var temp = images;
            temp = temp.concat(obj.Links)
            setImages(temp)
            console.log("images content", temp);
            //console.log("images contentccc", temp[0].Hash['/']);
        }
    }


    async function uploadMetas(e) {
        const files = e.target.files


        for (var i = 0; i < files.length; i++) {
            var reader = new FileReader();
            reader.fileName = files[i].name;
            reader.onload = onReaderLoad;
            reader.readAsText(files[i])
        }

        //reader.readAsText(files[0]);

        function onReaderLoad(event) {
            console.log(event.target.fileName)
            var obj = JSON.parse(event.target.result);
            var temp = metas;
            if (obj.tokenId == undefined) {
                obj['tokenId'] = event.target.fileName.split(".")[0];
                obj['image'] = event.target.fileName.split(".")[0] + ".jpg";
                obj.name = obj.name.split("#")[0] + "#" + event.target.fileName.split(".")[0];
                obj.name = obj.name.split("#")[0] + "#" + event.target.fileName.split(".")[0];
            }
            temp.push(obj)
            setMetas(temp)
            console.log("metas content preprocessed", metas);
        }

    }

    const handleProcess = () => {
        //console.clear()
        //debugger
        if (metas.length != images.length) {
            console.error("Invalid arrays length");
            return
        }
        console.log("Data integrity verification");
        let invalidImages = [];
        for (let i = 0; i < images.length; i++) {
            let found = false;
            for (let j = 0; j < metas.length; j++) {
                if (images[i].Name.split(".")[0] == metas[j].tokenId) {
                    found = true;
                }
            }
            if (!found) {
                invalidImages.push(images[i])
            }
        }
        let invalidMetas = [];
        for (let i = 0; i < metas.length; i++) {
            let found = false;
            for (let j = 0; j < images.length; j++) {
                if (metas[i].tokenId == images[j].Name.split(".")[0]) {
                    found = true;
                }
            }
            if (!found) {
                invalidMetas.push(metas[i])
            }
        }
        //debugger;
        if (invalidMetas.length > 0 || invalidImages.length > 0) {
            console.error('invalidMetas', invalidMetas)
            console.error('invalidImages', invalidImages)
            return;
        } else {

            for (let i = 0; i < metas.length; i++) {
                for (let j = 0; j < images.length; j++) {
                    if (metas[i].tokenId == images[j].Name.split(".")[0]) {
                        metas[i].image = 'ipfs://' + images[j].Hash['/']
                    }
                }
            }
            //debugger
            console.log("Processed result", metas)

        }

        zipResult(metas);

    }

    const zipResult = (metasToZip) => {
        const zip = require('jszip')();
        let files = metasToZip
        for (let file = 0; file < metasToZip.length; file++) {
            // Zip file with the file name.
            zip.file(metasToZip[file].tokenId + ".json", JSON.stringify(files[file]));
        }
        zip.generateAsync({ type: "blob" }).then(content => {
            //saveAs(content, "example.zip");
            setFinalZipFile(content)
            console.log("ziped", content)
            const element = document.createElement("a");
            element.href = URL.createObjectURL(content);
            element.download = "myFile.zip";
            document.body.appendChild(element);
            element.click();
        });
    }


    async function uploadProcessedFiles(e) {


        const files = e.target.files


        for (var i = 0; i < files.length; i++) {
            var reader = new FileReader();
            reader.onload = onReaderLoad;
            reader.readAsText(files[i])
        }

        //reader.readAsText(files[0]);

        function onReaderLoad(event) {
            var obj = JSON.parse(event.target.result);
            var temp = images;
            temp = temp.concat(obj.Links)
            console.log("metas content", temp);
            //console.log("images contentccc", temp[0].Hash['/']);
            let tokenUris = [];
            for (var i = 0; i < temp.length; i++) {

                const data = JSON.stringify(temp[i])
                console.log("stringify data", data)
                tokenUris.push({
                    tokenId: temp[i].Name.split(".")[0],
                    uri: temp[i].Hash['/']
                }
                )
            }

            console.log("final token uris", tokenUris)
            createSale(tokenUris)
        }
    }

    async function createItem() {

        let tokenUris = [];
        for (var i = 0; i < metas.length; i++) {

            const data = JSON.stringify(metas[i])
            console.log("stringify data", data)
            const added = await client.add(data)
            const url = added.path
            tokenUris.push(url)
        }

        console.log("final token uris 2", tokenUris)
        createSale(tokenUris)
    }

    async function createSale(tokenInfos) {
        console.log("aaaa1")
        const web3Modal = new Web3Modal()
        console.log("aaaa2")
        const connection = await web3Modal.connect()
        console.log("aaaa3")
        const provider = new ethers.providers.Web3Provider(connection)
        console.log("aaaa4")
        const signer = provider.getSigner()
        console.log("aaaa5")
        /* next, create the item */
        let contract = new ethers.Contract(AppParams.MAIN_TOKEN_CONTRACT_ADDRESS, MainTokenAbi, signer)

        const tokenYield = ethers.utils.parseUnits(formInput.yield, 'ether')
        let tokenIds = [];
        let tokenUris = [];
        let sellers = [];
        let categories = [];
        let tokenYields = [];

        function compare(a, b) {
            if (parseFloat(a.tokenId) < parseFloat(b.tokenId)) {
                return -1;
            }
            if (parseFloat(a.tokenId) > parseFloat(b.tokenId)) {
                return 1;
            }
            return 0;
        }

        tokenInfos.sort(compare);

        const jumpIds = 0;
        for (var i = 0; i < (tokenInfos.length); i++) {
            tokenInfos[i].tokenId = parseInt(tokenInfos[i].tokenId)
            if (tokenInfos[i].tokenId >= formInput.mintFrom && tokenInfos[i].tokenId <= formInput.mintTo) {
                tokenIds.push(parseInt(tokenInfos[i].tokenId) + jumpIds)
                tokenUris.push(tokenInfos[i].uri)
                sellers.push(formInput.seller)
                tokenYields.push(tokenYield)
                categories.push(formInput.category)
            }
        }
        console.log("tokenIds", tokenIds)
        console.log("tokensInfos", tokenInfos)

        const chunkSize = 10;
        for (let i = 0; i < tokenInfos.length; i += chunkSize) {
            const tokenIdsChunk = tokenIds.slice(i, i + chunkSize);
            const tokenUrisChunk = tokenUris.slice(i, i + chunkSize);
            const sellersChunk = sellers.slice(i, i + chunkSize);
            const categoriesChunk = categories.slice(i, i + chunkSize);
            if (tokenIdsChunk.length > 0) {
                let transaction = await contract.batchMintAndSell(formInput.seller, tokenIdsChunk, categoriesChunk, tokenUrisChunk)
                let tx = await transaction.wait()
            }
        }
    }

    return (
        <div>
            <Form>
                <div>
                    <GenerateNFTsMetas></GenerateNFTsMetas>
                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Number tokens to mint</Form.Label>
                        <Form.Control
                            type='number'
                            className='form-control'
                            placeholder='Number tokens to mint'
                            aria-label='Number tokens to mint'
                            value={formInput.tokensNumberToMint}
                            onChange={(e) =>
                                updateFormInput({ ...formInput, tokensNumberToMint: e.target.value })
                            }
                        />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Start mint from</Form.Label>
                        <Form.Control
                            type='number'
                            className='form-control'
                            placeholder='Start mint from'
                            aria-label='Start mint from'
                            value={formInput.mintFrom}
                            onChange={(e) =>
                                updateFormInput({ ...formInput, mintFrom: e.target.value })
                            }
                        />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Start mint to</Form.Label>
                        <Form.Control
                            type='number'
                            className='form-control'
                            placeholder='Start mint to'
                            aria-label='Start mint to'
                            value={formInput.mintTo}
                            onChange={(e) =>
                                updateFormInput({ ...formInput, mintTo: e.target.value })
                            }
                        />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                        <Form.Label>Upload Prececced Metas</Form.Label>
                        <input
                            type='file'
                            name='Asset'
                            className='my-4'
                            accept=".json"
                            onChange={uploadProcessedFiles}
                        />
                    </Form.Group>
                </div>
            </Form>
            <h3>Create Item</h3>
            <div style={{ display: 'inline-grid' }}>
                <span>1-Upload files to pinata</span>
                <span>2-Upload files to local node</span>
                <span>3-Execute in CLI: ipfs dag get [FolderCDI] &gt; [destinationFileName.json] </span>
                <span>4-Select exported file in "Upload photos"</span>
                <span>5-Select metas json files Upload Metas in "Upload Metas"</span>
                <span>6-Click process</span>
                <span>7-Extract procced files</span>
                <span>8-Upload Extracted files to pinata</span>
                <span>9-Upload Extracted files to local node</span>
                <span>10-Execute in CLI: ipfs dag get [FolderCDI] &gt; [destinationFileName.json] </span>
                <span>11-Select Extracted json files "Upload Prececced Metas"</span>
            </div>
            <Form>

                <div>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                        <Form.Label>Upload photos</Form.Label>
                        <input
                            type='file'
                            name='Asset'
                            className='my-4'
                            accept=".json"
                            onChange={uploadFiles}
                        />
                    </Form.Group>
                </div>

                <div>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                        <Form.Label>Upload Metas</Form.Label>
                        <input
                            type='file'
                            name='Asset'
                            className='my-4'
                            multiple
                            accept=".json"
                            onChange={uploadMetas}
                        />
                    </Form.Group>
                </div>

                <div>


                    <Button
                        onClick={handleProcess}
                    >
                        Process </Button>

                </div>

                <div>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                        <Form.Label>Upload Prececced Metas</Form.Label>
                        <input
                            type='file'
                            name='Asset'
                            className='my-4'
                            accept=".json"
                            onChange={uploadProcessedFiles}
                        />
                    </Form.Group>
                </div>

                <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Seller address</Form.Label>
                    <Form.Control
                        type='text'
                        className='form-control'
                        placeholder='Seller address'
                        aria-label='Seller address'
                        onChange={(e) =>
                            updateFormInput({ ...formInput, seller: e.target.value })
                        }
                    />
                    <Form.Text className='text-muted'>Seller address</Form.Text>
                </Form.Group>

                <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Yield</Form.Label>
                    <Form.Control
                        type='text'
                        className='form-control'
                        placeholder='Token rewards'
                        aria-label='Token rewards'
                        onChange={(e) =>
                            updateFormInput({ ...formInput, yield: e.target.value })
                        }
                    />
                    <Form.Text className='text-muted'>Token Yield</Form.Text>
                </Form.Group>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                        type='text'
                        className='form-control'
                        placeholder='Asset Price in AVAX'
                        aria-label='Dollar amount (with dot and two decimal places)'
                        onChange={(e) =>
                            updateFormInput({ ...formInput, price: e.target.value })
                        }
                    />
                    <Form.Text className='text-muted'>Price in AVAX</Form.Text>
                </Form.Group>



                {fileUrl && (
                    <img className='rounded mt-4' alt='MED' width='350' src={fileUrl} />
                )}

                {/* <Button variant='primary' type='submit' onClick={createItem}>
          Create Digital Asset
        </Button> */}
                {/* <button onClick={createItem}>Create Digital Asset</button> */}
                <div className='d-grid gap-2'>
                    <button
                        className='btn btn-lg btn-primary'
                        type='button'
                        onClick={createItem}
                        style={{ marginTop: 20 }}
                    >
                        Create Digital Asset
                    </button>
                </div>

                <div>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextareaMergeFiles'>
                        <Form.Label>MergeFiles</Form.Label>
                        <input
                            type='file'
                            name='Asset'
                            className='my-4'
                            accept=".json"
                            multiple
                            onChange={mergeFiles}
                        />
                    </Form.Group>
                </div>
            </Form>
        </div>
    )
}

export default BatchCreateItems
