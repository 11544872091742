import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ethers } from 'ethers'
import { create as ipfsHttpClient } from 'ipfs-http-client'

import Web3Modal from 'web3modal'

import { AppParams } from '../config'

import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from 'react-bootstrap'
import { AssetTokenAbi } from '../artifacts/contracts-abis/AssetToken-abi'

const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0')

const CreateAsset = () => {
  const navigate = useNavigate()

  const [fileUrl, setFileUrl] = useState(null)
  const [formInput, updateFormInput] = useState({
    seller:'0xE6585C6103f0972F408529b9C1452Ac0751b5699',
    multiplier:'',
    tokenId:'',
    price: '',
    name: '',
    description: '',
    category: 'EQUIPEMENT',
    avaxPayement: false,
    expireDays: '0'
  })

  async function onChange(e) {
    const file = e.target.files[0]
    try {
      const added = await client.add(file, {
        progress: (prog) => console.log(`received: ${prog}`),
      })
      const url = `https://ipfs.infura.io/ipfs/${added.path}`
      setFileUrl(url)
      console.log('url: ', url)
    } catch (error) {
      console.log('Error uploading file: ', error)
    }
  }
  async function createItem() {
    const { name, description, price } = formInput
    if (!name || !description || !price || !fileUrl) return
    /* first, upload to IPFS */
    const data = JSON.stringify({
      name,
      description,
      image: fileUrl,
    })
    try {
      const added = await client.add(data)
      const url = `https://ipfs.infura.io/ipfs/${added.path}`
      console.log(added.path)
      /* after file is uploaded to IPFS, pass the URL to save it on Polygon */
      createSale(added.path)
    } catch (error) {
      console.log('Error uploading file: ', error)
    }
  }

  async function createSale(url) {
    const web3Modal = new Web3Modal()
    const connection = await web3Modal.connect()
    const provider = new ethers.providers.Web3Provider(connection)
    const signer = provider.getSigner()

    /* next, create the item */
    let contract = new ethers.Contract(AppParams.ASSET_TOKEN_CONTRACT_ADDRESS,AssetTokenAbi, signer)

    const price = ethers.utils.parseUnits(formInput.price, 'ether')

    let transaction = await contract.mintAndSell(formInput.seller,formInput.tokenId,formInput.category,true,(parseInt(formInput.expireDays) * (86400))+"",(parseInt(formInput.multiplier) * (10**6))+"",price,url,formInput.avaxPayement)
    let tx = await transaction.wait()
  }

  return (
    <div>
      <h3>Create Item</h3>
      <Form>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            className='form-control'
            placeholder='Asset Name'
            aria-label='Sizing example input'
            aria-describedby='inputGroup-sizing-default'
            onChange={(e) =>
              updateFormInput({ ...formInput, name: e.target.value })
            }
          />
          <Form.Text className='text-muted'>Name of nft</Form.Text>
        </Form.Group>

        <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
          <Form.Label>Asset Description</Form.Label>
          <Form.Control
            as='textarea'
            rows={3}
            className='form-control'
            aria-label='With textarea'
            placeholder='Asset Description'
            onChange={(e) =>
              updateFormInput({ ...formInput, description: e.target.value })
            }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Seller address</Form.Label>
          <Form.Control
            type='text'
            className='form-control'
            placeholder='Seller address'
            aria-label='Seller address'
            onChange={(e) =>
              updateFormInput({ ...formInput, seller: e.target.value })
            }
          />
          <Form.Text className='text-muted'>Seller address</Form.Text>
        </Form.Group>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Token Id</Form.Label>
          <Form.Control
            type='input'
            className='form-control'
            placeholder='Token ID'
            aria-label='Token ID'
            onChange={(e) =>
              updateFormInput({ ...formInput, tokenId: e.target.value })
            }
          />
          <Form.Text className='text-muted'>Token ID</Form.Text>
        </Form.Group>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Multiplier</Form.Label>
          <Form.Control
            type='text'
            className='form-control'
            placeholder='Token rewards'
            aria-label='Token rewards'
            onChange={(e) =>
              updateFormInput({ ...formInput, multiplier: e.target.value })
            }
          />
          <Form.Text className='text-muted'>Multiplier</Form.Text>
        </Form.Group>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>expireDays</Form.Label>
          <Form.Control
            type='text'
            className='form-control'
            placeholder='Token rewards'
            aria-label='Token rewards'
            onChange={(e) =>
              updateFormInput({ ...formInput, expireDays: e.target.value })
            }
          />
          <Form.Text className='text-muted'>expireDays</Form.Text>
        </Form.Group>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Price</Form.Label>
          <Form.Control
            type='text'
            className='form-control'
            placeholder='Asset Price in O2B'
            aria-label='Dollar amount (with dot and two decimal places)'
            onChange={(e) =>
              updateFormInput({ ...formInput, price: e.target.value })
            }
          />
          <Form.Text className='text-muted'>Price in O2B</Form.Text>
        </Form.Group>

        <div>
          <input
            type='file'
            name='Asset'
            className='my-4'
            onChange={onChange}
          />
        </div>

        {fileUrl && (
          <img className='rounded mt-4' alt='MED' width='350' src={fileUrl} />
        )}

        {/* <Button variant='primary' type='submit' onClick={createItem}>
          Create Digital Asset
        </Button> */}
        {/* <button onClick={createItem}>Create Digital Asset</button> */}
        <div className='d-grid gap-2'>
          <button
            className='btn btn-lg btn-primary'
            type='button'
            onClick={createItem}
            style={{ marginTop: 20 }}
          >
            Create Digital Asset
          </button>
        </div>
      </Form>
    </div>
  )
}

export default CreateAsset
