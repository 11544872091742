import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import VaultItem from "../components/vault/VaultItem";
import GoldReserve from "../components/vault/GoldReserve";

import classes from "../components/vault/Vault.module.css";
import useDocument from "../Hooks/useDocument";
import { useEffect } from "react";
import { useState } from "react";
import TotalNft from "../components/vault/TotalNft";

const Vault = () => {
  const { document, isPending, error } = useDocument("vault", "vault");
  const [bullions, setBullions] = useState([]);

  useEffect(() => {
    let arr = [];
    if (document) {
      for (let key in document) {
        if (key.toLowerCase().startsWith("bul")) {
          arr.push(document[key]);
        }
      }
      setBullions(arr);
    }
  }, [document]);
  return (
    <section>
      <Container fluid="lg">
        <h2 className={`${classes.title} my-3 text-center `}>Vault</h2>
        <Row className="justify-content-center">
          <Col sm="6" md="6" lg="3">
            <GoldReserve
              text={"Ghold"}
              reserve={document && document.erc20Gold / 1000}
              pending={isPending}
            />
          </Col>
          <Col sm="6" md="6" lg="3">
            <GoldReserve
              text={"NFT"}
              reserve={document && document.nftsGold / 1000}
              pending={isPending}
            />
          </Col>
          <Col sm="6" md="6" lg="3">
            <GoldReserve text={"Reserve"} reserve={5} pending={isPending} />
          </Col>
          <Col sm="6" md="6" lg="3">
            <GoldReserve
              text={"Total"}
              reserve={
                document &&
                document.erc20Gold / 1000 + document.nftsGold / 1000 + 5
              }
              pending={isPending}
            />
          </Col>
        </Row>
        <Row className="d-none d-md-flex justify-content-between my-1 rounded p-2 border-1 align-items-center w-100">
          <Col sm="2">
            <p className="d-block m-0 p-0">Products</p>
          </Col>
          <Col sm="2">
            <p className="d-block m-0 p-0">Quantity</p>
          </Col>
          <Col
            sm="2"
            className="d-flex justify-content-center align-items-center"
          >
            <p className="d-block m-0 p-0">Total</p>
          </Col>
          <Col sm="2">
            <p className="d-block m-0 p-0"></p>
          </Col>
        </Row>
        {!error &&
          document &&
          bullions &&
          bullions.map((bul) => {
            return <VaultItem {...bul} key={bul.name} />;
          })}
        <Row className="d-none d-md-flex justify-content-between rounded border-1 align-items-center w-100">
          {" "}
          {!error && document && bullions && <TotalNft bullions={bullions} />}
        </Row>
      </Container>
    </section>
  );
};

export default Vault;
