import { useCallback } from "react";
import { currentMode } from "../config";
import { useSetRecoilState } from "recoil";
import { goldPriceState } from "../services/atoms";
import UseHttp from "./useHttp";
import secret from "../secret.json";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { useEffect } from "react";

export default function useGetGoldPrice() {
  const setGoldPrice = useSetRecoilState(goldPriceState);
  const { sendRequest, isPending, success, error } = UseHttp();

  const getPriceFromDB = useCallback(async () => {
    try {
      const docRef = doc(db, "goldPrice", "price");
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      return data.price;
    } catch (error) {
      throw new Error("We cloud not get the gold price from the database");
    }
  }, []);
  const getGoldPrice = useCallback(async () => {
    setGoldPrice((prevState) => {
      return { ...prevState, isLoading: true, success: false, error: "" };
    });
    if (currentMode === "recette") {
      try {
        await sendRequest(
          {
            url: `https://www.goldapi.io/api/XAU/USD`,
            headers: {
              "x-access-token":
                currentMode === "recette"
                  ? secret.GOLD_API_KEY_RECETTE
                  : secret.GOLD_API_KEY_DEV,
              "Content-Type": "application/json",
            },
          },

          (data) => {
            if (data.error) {
              throw new Error("Something went wrong ");
            }
            setGoldPrice((prevState) => {
              return {
                ...prevState,
                value: data.price_gram_24k,
                isLoading: false,
                success: true,
              };
            });
          }
        );
      } catch (error) {
        try {
          const price = await getPriceFromDB();
          setGoldPrice((prevState) => {
            return {
              ...prevState,
              value: price,
              isLoading: false,
              success: true,
            };
          });
        } catch (error) {
          setGoldPrice((prevState) => {
            return {
              ...prevState,
              error: "Something went wrong we cloud get the current gold price",
              isLoading: false,
            };
          });
        }
      }
    } else {
      try {
        const price = await getPriceFromDB();
        setGoldPrice((prevState) => {
          return {
            ...prevState,
            value: price,
            isLoading: false,
            success: true,
          };
        });
      } catch (error) {
        setGoldPrice((prevState) => {
          return {
            ...prevState,

            isLoading: false,
            success: false,
            error: "Something went wrong we cloud get the current gold price",
          };
        });
      }
    }
  }, [getPriceFromDB, sendRequest]);
  return { getGoldPrice };
}
