import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ethers } from 'ethers'
import { create as ipfsHttpClient } from 'ipfs-http-client'

import Web3Modal from 'web3modal'


import {
    Button,
    Form
} from 'react-bootstrap'
import { FeesReducerTokenAbi } from '../artifacts/contracts-abis/ReducerToken-abi'
import { AppParams } from '../config'

const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0')

const BatchCreateReducer = () => {
    const navigate = useNavigate()

    const [fileUrl, setFileUrl] = useState(null)
    /*const [formInput, updateFormInput] = useState({
        seller: '0xE6585C6103f0972F408529b9C1452Ac0751b5699',
        price: '1',
        category: 'EQUIPEMENT',
        avaxPayement: false,
        expirable: true,
        expireIn: '3',
        multiplier: '10'
    })*/

    const [metas, setMetas] = useState([]);
    const [images, setImages] = useState([]);
    const [finalZipFile, setFinalZipFile] = useState(null);


    async function uploadFiles(e) {


        const files = e.target.files


        for (var i = 0; i < files.length; i++) {
            var reader = new FileReader();
            reader.onload = onReaderLoad;
            reader.readAsText(files[i])
        }

        //reader.readAsText(files[0]);

        function onReaderLoad(event) {
            var obj = JSON.parse(event.target.result);
            var temp = images;
            temp = temp.concat(obj.Links)
            setImages(temp)
            console.log("images content", temp);
            //console.log("images contentccc", temp[0].Hash['/']);
        }
    }


    async function uploadMetas(e) {
        const files = e.target.files


        for (var i = 0; i < files.length; i++) {
            var reader = new FileReader();
            reader.fileName = files[i].name;
            reader.onload = onReaderLoad;
            reader.readAsText(files[i])
        }

        //reader.readAsText(files[0]);

        function onReaderLoad(event) {
            console.log(event.target.fileName)
            var obj = JSON.parse(event.target.result);
            var temp = metas;
            if (obj.tokenId == undefined) {
                obj['tokenId'] = event.target.fileName.split(".")[0];
                obj['image'] = event.target.fileName.split(".")[0] + ".jpg";
                obj.name = obj.name.split("#")[0] + "#" + event.target.fileName.split(".")[0];
                obj.name = obj.name.split("#")[0] + "#" + event.target.fileName.split(".")[0];
            }
            temp.push(obj)
            setMetas(temp)
            console.log("metas content preprocessed", metas);
        }

    }

    const handleProcess = () => {
        //console.clear()
        //debugger
        if (metas.length != images.length) {
            console.error("Invalid arrays length");
            return
        }
        console.log("Data integrity verification");
        let invalidImages = [];
        for (let i = 0; i < images.length; i++) {
            let found = false;
            for (let j = 0; j < metas.length; j++) {
                if (images[i].Name.split(".")[0] == metas[j].tokenId) {
                    found = true;
                }
            }
            if (!found) {
                invalidImages.push(images[i])
            }
        }
        let invalidMetas = [];
        for (let i = 0; i < metas.length; i++) {
            let found = false;
            for (let j = 0; j < images.length; j++) {
                if (metas[i].tokenId == images[j].Name.split(".")[0]) {
                    found = true;
                }
            }
            if (!found) {
                invalidMetas.push(metas[i])
            }
        }
        //debugger;
        if (invalidMetas.length > 0 || invalidImages.length > 0) {
            console.error('invalidMetas', invalidMetas)
            console.error('invalidImages', invalidImages)
            return;
        } else {

            for (let i = 0; i < metas.length; i++) {
                for (let j = 0; j < images.length; j++) {
                    if (metas[i].tokenId == images[j].Name.split(".")[0]) {
                        metas[i].image = 'ipfs://' + images[j].Hash['/']
                    }
                }
            }
            //debugger
            console.log("Processed result", metas)

        }

        zipResult(metas);

    }

    const zipResult = (metasToZip) => {
        const zip = require('jszip')();
        let files = metasToZip
        for (let file = 0; file < metasToZip.length; file++) {
            // Zip file with the file name.
            zip.file(metasToZip[file].tokenId + ".json", JSON.stringify(files[file]));
        }
        zip.generateAsync({ type: "blob" }).then(content => {
            //saveAs(content, "example.zip");
            setFinalZipFile(content)
            console.log("ziped", content)
            const element = document.createElement("a");
            element.href = URL.createObjectURL(content);
            element.download = "myFile.zip";
            document.body.appendChild(element);
            element.click();
        });
    }


    async function uploadProcessedFiles(e) {


        const files = e.target.files


        for (var i = 0; i < files.length; i++) {
            var reader = new FileReader();
            reader.onload = onReaderLoad;
            reader.readAsText(files[i])
        }

        //reader.readAsText(files[0]);

        function onReaderLoad(event) {
            var obj = JSON.parse(event.target.result);
            var temp = images;
            temp = temp.concat(obj.Links)
            console.log("metas content", temp);
            //console.log("images contentccc", temp[0].Hash['/']);
            let tokenUris = [];
            for (var i = 0; i < temp.length; i++) {

                const data = JSON.stringify(temp[i])
                console.log("stringify data", data)
                tokenUris.push({
                    tokenId: temp[i].Name.split(".")[0],
                    uri: temp[i].Hash['/']
                }
                )
            }

            console.log("final token uris", tokenUris)
            createSale(tokenUris)
        }
    }

    async function createItem() {

        let tokenUris = [];
        for (var i = 0; i < metas.length; i++) {

            const data = JSON.stringify(metas[i])
            console.log("stringify data", data)
            const added = await client.add(data)
            const url = added.path
            tokenUris.push(url)
        }

        console.log("final token uris", tokenUris)
        createSale(tokenUris)
    }

    const formInput = {
        //seller: '0xE6585C6103f0972F408529b9C1452Ac0751b5699',
        seller: '0x50a5b931c6E1B824C5d18791524B8FdF63aE894f',
        category: 'FEES-REDUCERS',
        //expirable: true,
        //expireIn: '3',
        //multiplier: '10',
        //price: '1',
        avaxPayement: false,
    }

    function compare(a, b) {
        if (parseFloat(a.tokenId) < parseFloat(b.tokenId)) {
            return -1;
        }
        if (parseFloat(a.tokenId) > parseFloat(b.tokenId)) {
            return 1;
        }
        return 0;
    }

    async function createSale(tokenInfos) {
        const web3Modal = new Web3Modal()
        const connection = await web3Modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()

        /* next, create the item */
        let contract = new ethers.Contract(AppParams.FEES_REDUCER_TOKEN_CONTRACT_ADDRESS, FeesReducerTokenAbi, signer)

        
        //const tokenYield = ethers.utils.parseUnits(formInput.yield, 'ether')


        let sortedMetas = metas;
        sortedMetas.sort(compare)
        console.log(sortedMetas)
        tokenInfos.sort(compare);

        let sellers = [];
        let tokenIds = [];
        let tokenUris = [];
        let categories = [];
        let prices = [];
        let useNumbers = [];
        let rates = [];

        const jumpIds = 0;

        for (var i = 0; i < (tokenInfos.length); i++) {
            tokenInfos[i].tokenId = parseInt(tokenInfos[i].tokenId)
            if (tokenInfos[i].tokenId >= 11 && tokenInfos[i].tokenId <= 20) {
                tokenIds.push(parseInt(tokenInfos[i].tokenId)+jumpIds)
                categories.push(formInput.category)
                //expirables.push(formInput.expirable)
                //expireDays.push((parseInt(sortedMetas[i].validity) * (86400)) + "")
                //multipliers.push((parseInt(sortedMetas[i].ability) * (10 ** 6)) + "")
                useNumbers.push(parseInt(sortedMetas[i].validity))
                rates.push((parseInt(sortedMetas[i].ability) * (10 ** 6)) + "")
                prices.push(ethers.utils.parseUnits(sortedMetas[i].price+"", 'ether'))
                tokenUris.push(tokenInfos[i].uri)
            }
        }
        console.log("prices.length", prices.length)
        console.log("tokenIds", tokenIds)
        console.log("tokensInfos", tokenInfos)
        let transaction = await contract.batchMintAndSell(
            formInput.seller,
            tokenIds,
            categories,
            useNumbers,
            rates,
            prices,
            tokenUris,
            formInput.avaxPayement
        )
        //let transaction = await contract.mintAndSell(formInput.seller,formInput.tokenId,formInput.category,true,(parseInt(formInput.expireDays) * (86400))+"",(parseInt(formInput.multiplier) * (10**6))+"",price,url,formInput.avaxPayement)

        let tx = await transaction.wait()
    }

    return (
        <div>
            <h3>Create Equipements</h3>
            <div style={{ display: 'inline-grid' }}>
                <span>1-Upload files to pinata</span>
                <span>2-Upload files to local node</span>
                <span>3-Execute in CLI: ipfs dag get [FolderCDI] &gt; [destinationFileName.json] </span>
                <span>4-Select exported file in "Upload photos"</span>
                <span>5-Select metas json files Upload Metas in "Upload Metas"</span>
                <span>6-Click process</span>
                <span>7-Extract procced files</span>
                <span>8-Upload Extracted files to pinata</span>
                <span>9-Upload Extracted files to local node</span>
                <span>10-Execute in CLI: ipfs dag get [FolderCDI] &gt; [destinationFileName.json] </span>
                <span>11-Refresh app (optional)</span>
                <span>12-Verify jumpIds variable to add to token id</span>
                <span>13-Reupload initial metas json files Upload Metas in "Upload Metas" (optional)</span>
                <span>14-Select Extracted json files "Upload Prececced Metas"</span>
            </div>
            <Form>

                <div>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                        <Form.Label>Upload photos</Form.Label>
                        <input
                            type='file'
                            name='Asset'
                            className='my-4'
                            accept=".json"
                            onChange={uploadFiles}
                        />
                    </Form.Group>
                </div>

                <div>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                        <Form.Label>Upload Metas</Form.Label>
                        <input
                            type='file'
                            name='Asset'
                            className='my-4'
                            multiple
                            accept=".json"
                            onChange={uploadMetas}
                        />
                    </Form.Group>
                </div>

                <div>


                    <Button
                        onClick={handleProcess}
                    >
                        Process </Button>

                </div>

                <div>
                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                        <Form.Label>Upload Prececced Metas</Form.Label>
                        <input
                            type='file'
                            name='Asset'
                            className='my-4'
                            accept=".json"
                            onChange={uploadProcessedFiles}
                        />
                    </Form.Group>
                </div>





                {fileUrl && (
                    <img className='rounded mt-4' alt='MED' width='350' src={fileUrl} />
                )}

                {/* <Button variant='primary' type='submit' onClick={createItem}>
          Create Digital Asset
        </Button> */}
                {/* <button onClick={createItem}>Create Digital Asset</button> */}
                <div className='d-grid gap-2'>
                    <button
                        className='btn btn-lg btn-primary'
                        type='button'
                        onClick={createItem}
                        style={{ marginTop: 20 }}
                    >
                        Create Digital Asset
                    </button>
                </div>
            </Form>
        </div>
    )
}

export default BatchCreateReducer
