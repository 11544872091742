import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import nfts from "../../data/nftCollection.json";
import WalletNft from "./WalletNft";
import NftItem from "./NftItem";
import { AppParams } from "../../config";
import { Alchemy, Network } from "alchemy-sdk";
import { useRecoilValue } from "recoil";
import { accountState } from "../../services/atoms";
import { useEffect } from "react";
import Loader from "../../UI/Loader";
import classes from "./Wallet.module.css";

const NftCollection = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [nfts, setNfts] = useState([]);
  const account = useRecoilValue(accountState);
  const getNfts = useCallback(async () => {
    setIsLoading(true);
    setError("");
    setSuccess(false);
    try {
      const settings = {
        apiKey: "I3HH-eMusjU1Vj2LgCDjFrQtUoYnsonO",
        network: Network.ETH_GOERLI,
      };

      const alchemy = new Alchemy(settings);

      const nfts = await alchemy.nft.getNftsForOwner(account);

      setSuccess(true);
      setNfts(
        nfts.ownedNfts.filter(
          (nft) =>
            nft.contract.address.toLowerCase() ===
            AppParams.NFT_CONTRACT_ADDRESS.toLowerCase()
        )
      );
    } catch (error) {
      console.log(error);
      setSuccess(false);
      setError("Something went wrong, please try later");
    } finally {
      setIsLoading(false);
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      getNfts();
    } else {
      setIsLoading(false);
    }
  }, [account]);

  return (
    <>
      <Row>
        <h2 className="mx-auto text-center my-3 mt-3">My Ghold NFT</h2>
      </Row>
      <Row className="d-flex justify-content-between my-1 rounded p-2 border-1 align-items-center w-100">
        <Col sm="2">
          <p className="d-block m-0 p-0">NFT</p>
        </Col>
        <Col sm="2">
          <p className="d-block m-0 p-0">Value</p>
        </Col>
        <Col sm="2">
          <p className="d-block m-0 p-0">Token ID</p>
        </Col>
        <Col sm="2"></Col>
      </Row>
      {isLoading && (
        <div
          className={`${classes["loader-container"]} d-flex justify-content-center align-items-center`}
        >
          <Loader width={"26"} height={"26"} />
        </div>
      )}
      {success && nfts.length !== 0 && (
        <>
          {nfts.map((nft) => {
            return (
              <NftItem
                {...nft.rawMetadata}
                key={nft.tokenId}
                tokenId={nft.tokenId}
                address={AppParams.NFT_CONTRACT_ADDRESS}
              />
            );
          })}
        </>
      )}
      {success && nfts.length === 0 && (
        <Row className={`${classes.item} text-white p-3`}>
          <p className="text-center">Empty</p>
        </Row>
      )}
      {error && (
        <Row className={`${classes.item} text-white p-3`}>
          <p className="text-center"> {error}</p>
        </Row>
      )}
    </>
  );
};

export default NftCollection;
