import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import midasClasses from "../../data/midasClasses.json";
import classes from "./MidasTouch.module.css";

import { useState } from "react";
import Loader from "../../UI/Loader";
import { db } from "../../firebase/firebaseConfig";
import { getDoc } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { accountState, midasFilterTermState } from "../../services/atoms";
import numberOfDecimals from "../../utils/numberOfDecimals";

const MidasClassModal = (props) => {
  const [selectedClass, setSelectedClass] = useState("");
  const [addingState, setAddingState] = useState({
    isPending: false,
    success: false,
    error: "",
  });
  const account = useRecoilValue(accountState);
  const setFilterTerm = useSetRecoilState(midasFilterTermState);
  const clickHandler = async () => {
    if (!selectedClass) return;
    if (props.updating === "true") {
      try {
        setAddingState((prevState) => {
          return { ...prevState, isPending: true, error: "", success: false };
        });
        const docRef = doc(db, "midas", props.id);
        const docSnap = await getDoc(docRef);
        const data = docSnap.data();
        await setDoc(docRef, {
          ...data,
          value: parseFloat(data.value) + parseFloat(selectedClass),
        });

        setAddingState((prevState) => {
          return { ...prevState, isPending: false, error: "", success: true };
        });
        props.onHide();
        return;
      } catch (error) {
        setAddingState((prevState) => {
          return {
            ...prevState,
            isPending: false,
            error: "Something went wrong please try later",
            success: false,
          };
        });

        return;
      }
    }
    try {
      setAddingState((prevState) => {
        return { ...prevState, isPending: true, error: "", success: false };
      });
      const customId = `${props.address}${props.id}`;
      const docRef = doc(db, "midas", customId);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();

      if (typeof data !== "undefined") {
        setAddingState((prevState) => {
          return {
            ...prevState,
            isPending: false,
            error: "NFT already exist",
            success: false,
          };
        });
        props.onHide();
        return;
      }

      await setDoc(docRef, {
        address: props.address,
        tokenId: props.id,
        value: selectedClass,
        imageUrl: props.image ? props.image : "a",
        owner: account.toLowerCase(),
        tokenName: props.name ? props.name : "Not provided !",
      });

      setAddingState((prevState) => {
        return { ...prevState, isPending: false, error: "", success: true };
      });
      setFilterTerm("touched");
      props.onHide();
    } catch (error) {
      console.log(error);
      setAddingState((prevState) => {
        return {
          ...prevState,
          isPending: false,
          error: "Something went wrong",
          success: false,
        };
      });
      props.onHide();
    }
  };

  const changeHandler = (e) => {
    if (numberOfDecimals(e.currentTarget.value) > 4) return;

    setSelectedClass(e.currentTarget.value);
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{padding:'0'}}>
        <h3 className="m-0">Add gold value</h3>
      </Modal.Header>
      <Modal.Body>
      
          {/* {midasClasses.map((cl, index) => {
            return (
              <Col
                sm="3"
                className="d-flex justify-content-start align-items-center"
              >
                <input
                  type="radio"
                  name="midasClass"
                  id={cl.name}
                  value={cl.value}
                  className={`${classes.input}`}
                  onChange={(e) => {
                    radioChangeHandler(e);
                  }}
                />
                <label htmlFor={cl.name}>{cl.name}</label>
              </Col>
            );
          })} */}
          <div style={{textAlign:'center'}}>
          <input
            type="number"
            name="value"
            onChange={changeHandler}
            value={selectedClass}
            placeholder="Enter value in Ghold"
            className={`${classes.input}`}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center align-items-center">
        <button className="btnConn me-2" onClick={props.onHide}>
          Close
        </button>
        <button
          className="btnConn"
          onClick={clickHandler}
          disabled={addingState.isPending}
        >
          {addingState.isPending ? (
            <span>
              <span className="d-inline-block me-1">Pending</span>{" "}
              <Loader width={"20"} height={"20"} />
            </span>
          ) : (
            "Confirm"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MidasClassModal;
