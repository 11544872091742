import React from "react";
import Loader from "../../UI/Loader";

function GoldReserve({ reserve, text, pending }) {
  return (
    <>
      <h1 className="h5 text-white text-center">{text}</h1>
      <div className="balance2 mb-3 d-flex justify-content-center align-items-center card">
        <div> {pending && <Loader width={"18"} height={"18"} />}</div>
        {!pending && (
          <div className="d-flex justify-content-center align-items-center">
            {" "}
            <span className="d-inline-block">
              {" "}
              {reserve.toString().slice(0, 5)}
            </span>{" "}
            <small className="d-inline-block ms-1">kg</small>
          </div>
        )}
      </div>
    </>
  );
}

export default GoldReserve;
