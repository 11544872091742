import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { accountState } from "../../services/atoms";
import { AppParams } from "../../config";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import numberFixDecimals from "../../utils/FixNumbers";
import Loader from "../../UI/Loader";
import BigNumber from "bignumber.js";
import logo from "../../assets/base_icon_white_background.png";
import classes from "./Wallet.module.css";

function BalanceBox({ balance, symbol, decimals }) {
  const account = useRecoilValue(accountState);
  const [userBalance, setUserBalance] = useState({
    isLoading: false,
    success: false,
    error: "",
    balance: 0,
  });
  const [tokenDecimals, setTokenDecimals] = useState(18);
  const [tokenSymbol, setTokenSymbol] = useState();
  const navigate = useNavigate();

  //   useEffect(() => {
  //     if (!account) {
  //       navigate("/");
  //     }
  //   }, [account]);

  const getBalance = useCallback(async () => {
    setUserBalance((prevState) => {
      return { ...prevState, isLoading: true, error: "", success: false };
    });
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      //const provider = new ethers.providers.Web3Provider(connection)
      const signer = provider.getSigner(account);
      const tokenContract = new ethers.Contract(
        AppParams.PLATFORM_TOKEN_ADDRESS,
        AppParams.PLATFORM_TOKEN_ABI,
        signer
      );
      const balance = await tokenContract.balanceOf(account);
      const decimals = await tokenContract.decimals();
      const symbol = await tokenContract.symbol();
      setTokenSymbol(symbol);
      setTokenDecimals(decimals);
      const bal = new BigNumber(balance.toString())
        .dividedBy(new BigNumber("10").pow(new BigNumber(decimals.toString())))
        .toNumber();

      setUserBalance((prevState) => {
        return {
          ...prevState,
          isLoading: false,
          error: "",
          success: true,
          balance: bal.toFixed(numberFixDecimals(bal)),
        };
      });
    } catch (error) {
      console.log(error);
      setUserBalance((prevState) => {
        return {
          ...prevState,
          isLoading: false,
          error: "Something went wrong,we can't get your balance now",
          success: false,
        };
      });
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      getBalance();
    }
  }, [getBalance, account]);
  return (
    <>
      <h2 className="text-center my-3 mb-3">My Ghold</h2>
      {userBalance.isLoading && (
        <div className="balance d-flex justify-content-center align-items-center">
          <Loader width={20} height={20} />
        </div>
      )}
      {userBalance.success && (
        <div className="d-flex justify-content-center align-items-center card rounded balance">
          <span>{userBalance.balance}</span>
          <span className="symbol">{tokenSymbol}</span>
          <span>
            <img
              src="https://res.cloudinary.com/decpwvhfk/image/upload/v1676898874/Rpw%20v3/base_icon_white_background_qdgilz.png"
              width="30"
              alt="logo"
              className={classes.logo}
            />
          </span>
          <button className="btnConn mb-3 mt-2">Sell</button>
        </div>
      )}

      {!userBalance.isLoading && userBalance.error && (
        <div className="balance d-flex justify-content-center align-items-center card">
          <p className="m-0 p-0 text-danger error"> {userBalance.error}</p>
        </div>
      )}
    </>
  );
}

export default BalanceBox;
