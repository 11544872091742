export default function bullionRepartition(num) {
  const numb = parseFloat(num);
  const bul1000 = numb / 1000;
  const bullion1000 = Math.floor(bul1000);
  const bul50 = numb % 1000;
  const bullion50 = Math.floor(bul50 / 50);
  const bul20 = bul50 % 50;
  const bullion20 = Math.floor(bul20 / 20);
  const bul10 = bul20 % 20;
  const bullion10 = Math.floor(bul10 / 10);
  const bul1 = bul10 % 10;
  const bullion1 = Math.floor(bul1);

  return { bullion1000, bullion50, bullion20, bullion10, bullion1 };
}
