import ApolloClient, { gql } from "apollo-boost";
import { AppParams_dev } from "./environnements/envDev";
import { AppParams_local } from "./environnements/envLocal";
import { AppParams_prod } from "./environnements/envProd";
import { AppParams_recette } from "./environnements/envRecette";

// export const currentMode = "recette";
export const currentMode = "dev";
// export const currentMode = "dev"

function getAppParams() {
  if (currentMode == "dev") {
    return AppParams_dev;
  } else if (currentMode == "prod") {
    return AppParams_prod;
  } else if (currentMode == "recette") {
    return AppParams_recette;
  } else if (currentMode == "local") {
    return AppParams_local;
  }
  return AppParams_dev;
}

export const AppParams = getAppParams();

export const StakingClient = new ApolloClient({
  uri: AppParams.STAKING_GRAPH,
});
export const MarketPlaceClient = new ApolloClient({
  uri: AppParams.NFT_MARKET_GRAPH,
});

export const TokensClient = new ApolloClient({
  uri: AppParams.TOKEN_GRAPH,
});

export const tokenValue = (isAvax, amount) => {
  return amount * (isAvax ? avaxValue() : antValue());
};
export const avaxValue = () => {
  return 0;
};
export const antValue = () => {
  return 1;
};

export const networks = {
  etheurem: {
    chainId: "0x1",
  },
  goerli: {
    chainId: "0x5",
  },
};
