import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import BalanceBox from "../components/wallet/BalanceBox";
import NftCollection from "../components/wallet/NftCollection";
import ERC20PurchaseHistory from "../components/wallet/ERC20PurchaseHistory";
import useGetGoldPrice from "../Hooks/useGetGoldPrice";
import { useEffect } from "react";

const Wallet = () => {
  const { getGoldPrice } = useGetGoldPrice();

  useEffect(() => {
    getGoldPrice();
  }, [getGoldPrice]);
  return (
    <section>
      <Container fluid="lg">
        <Row className="justify-content-center">
          <Col md="6" lg="4">
            <BalanceBox />
          </Col>
        </Row>
        <NftCollection />
        <ERC20PurchaseHistory />
      </Container>
    </section>
  );
};

export default Wallet;
