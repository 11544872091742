import React, { useEffect, useState } from 'react'
import { pendingTransactions, purchaseEnd, refreshIndex } from '../services/atoms'
import { useRecoilState } from 'recoil'

import { Badge, Button, ListGroup, Modal, Spinner } from 'react-bootstrap'
import { shortenTxId } from '../utils/shortenAddress'
import { AppParams, MarketPlaceClient } from '../config'
import { marketplaceItemById } from '../services/graphql'

import { useInterval } from 'usehooks-ts'
import { Link } from 'react-router-dom'

import { useNavigate } from 'react-router-dom'

const NotificationBar = () => {
  const [pendings, setPendings] = useRecoilState(pendingTransactions)
  const [modalPendingOpened, setModalPendingOpened] = useState(false)
  const [modalTokenPurchasedOpened, setModalTokenPurchasedOpened] =
  useRecoilState(purchaseEnd)
  const [refresh, setRefresh] = useRecoilState(refreshIndex)
  const [validatedTokenPurchase, setValidatedTokenPurchase] = useState({
    tokenId: '',
    contract: '',
  })

  useEffect(() => {
    let pendingString = localStorage.getItem('pendingTransactions')
    if (pendingString != null) {
      let temp = JSON.parse(pendingString)
      setPendings(temp)
      //for(var i = 0; i<temp.le)
      /*temp.push({
                txId: "temp.hash",
                itemId: 'itemId',
                finished: 'false'
            })*/

      //setPendings(temp);
    }
  }, [])

  useInterval(() => {
    verifyElements()
  }, 5000)

  useEffect(() => {
    let pendingString = JSON.stringify(pendings)
    localStorage.setItem('pendingTransactions', pendingString)
  }, [pendings])

  const handleViewMyAsset = () => {
    navigate(`/my-assets`)
    setModalTokenPurchasedOpened(false)
  }

  const verifyElements = () => {
    pendings.map((element) => verifyElement(element.itemId))
  }

  const verifyElement = (itemId) => {
    return MarketPlaceClient.query({
      query: marketplaceItemById,
      fetchPolicy: 'network-only',
      variables: {
        itemId: parseInt(itemId),
      },
    }).then((res) => {
      let availableForSale = res.data.nfts[0].availableForSale
      if (availableForSale == false) {
        setValidatedTokenPurchase({
          tokenId: res.data.nfts[0].tokenId,
          contract: res.data.nfts[0].contract.id,
        })
        setModalTokenPurchasedOpened(true)
        let items = pendings.filter((el) => el.itemId !== itemId)
        setPendings(items)
        setRefresh(refresh + 1)
      }
      console.log({ data: availableForSale })
    })
  }
  const navigate = useNavigate()

  /*Modal*/
  const showItem = (pending) => {
    return (
      <ListGroup.Item
        as='li'
        className='d-flex justify-content-between align-items-start'
        key={pending.txId}
      >
        <div className='ms-2 me-auto d-f-l'>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <div>ITEM ID : </div>
            <div> {pending.itemId}</div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <div>TX ID : </div>
            <div className='adresst'>
              {' '}
              <a
                style={{ color: '#baff85' }}
                href={AppParams.BLOCK_EXPLORER_URL + 'tx/' + pending.txId}
                target='_blank'
              >
                {shortenTxId(pending.txId)}
              </a>
            </div>
          </div>
        </div>
        {/*<Badge bg="primary" pill>
                    14
                </Badge>*/}
      </ListGroup.Item>
    )
  }

  return (
    <div>
      {pendings.length > 0 ? (
        <div className='btnPending'>
          <Spinner animation='border' role='status'></Spinner>
          <span
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              setModalPendingOpened(true)
            }}
          >
            {pendings.length} Pending transactions
          </span>
        </div>
      ) : null}

      <Modal
        show={modalPendingOpened}
        onHide={(e) => {
          setModalPendingOpened(false)
        }}
        backdrop='static'
        keyboard={false}
        className='pend'
      >
        <Modal.Header></Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          {pendings.length > 0 ? (
            <ListGroup as='ol'>
              {pendings.map((pending) => showItem(pending))}
            </ListGroup>
          ) : (
            <span>No pending transaction</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={(e) => {
              setModalPendingOpened(false)
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalTokenPurchasedOpened}
        onHide={(e) => {
          setModalTokenPurchasedOpened(false)
        }}
        backdrop='static'
        keyboard={false}
        
      >
        <Modal.Header style={{justifyContent:'flex-end'}}>
          
       <span
                      variant='secondary'
                      onClick={(e) => {
                        setModalTokenPurchasedOpened(false)
                      }}
                      style={{ fontSize: 20, cursor: 'pointer' }}
                  >
                      x
                  </span>
          
          </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
        <h4 style={{ textAlign:'center' , marginBottom:35,color:'#fff' }}>Asset added to your collection</h4>
        You purchase is completed. Check your asset details 
        </Modal.Body>
        <Modal.Footer  style={{justifyContent:'center'}}>
          {/*<Button variant="secondary" onClick={(e) => { setModalTokenPurchasedOpened(false) }}>
                        Ok
                    </Button> */}
          <Button onClick={handleViewMyAsset}>Here</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default NotificationBar
