import React from "react";

import { Col, Row } from "react-bootstrap";
import classes from "./Wallet.module.css";
import { AppParams } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import BigNumber from "bignumber.js";
import { useRecoilValue } from "recoil";
import { goldPriceState } from "../../services/atoms";
import fixNum from "../../utils/FixNumbers";
const NftItem = ({
  imageURL,
  categoryName,
  price,
  tokenClass,
  tokenId,
  address,
}) => {
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(`/nft/${tokenId}/${categoryName}/${address}`);
  };
  const goldPrice = useRecoilValue(goldPriceState);
  return (
    <Row
      className={`${classes.item} d-flex justify-content-between my-1 rounded p-2 border-1 align-items-center w-100`}
    >
      <Col sm="12" md="2">
        <div className=" d-flex">
          <p className="d-md-none me-2">Token:</p>
          <p className={`tokenTa ${classes.nft}`} onClick={clickHandler}>
            <img src={imageURL} width="40" className="me-1" alt="logo" />
            {categoryName}
          </p>
        </div>
      </Col>

      <Col sm="12" md="2">
        <div className="d-flex">
          <p className="me-2 d-md-none">Value:</p>
          {goldPrice.success && (
            <p>{`${categoryName} (${new BigNumber(
              parseInt(categoryName).toString()
            )
              .multipliedBy(new BigNumber(goldPrice.value.toString()))
              .toNumber()
              .toFixed(
                fixNum(
                  new BigNumber(parseInt(categoryName).toString())
                    .multipliedBy(new BigNumber(goldPrice.value.toString()))
                    .toNumber()
                )
              )}$) `}</p>
          )}
        </div>
      </Col>
      <Col sm="12" md="2">
        <div className="d-flex">
          <p className="me-2 d-md-none">Token ID:</p>
          <p>{tokenId}</p>
        </div>
      </Col>
      <Col sm="2">
        {" "}
        <button className="btnConn">Sell</button>
      </Col>
    </Row>
  );
};

export default NftItem;
