import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase/firebaseConfig";

const useFirestore = (col) => {
  const [isPending, setIsPending] = useState(null);
  const [error, setError] = useState(null);
  const [isCancelled, setIsCancelled] = useState(null);
  const [success, setSuccess] = useState(null);

  //   add item
  const addDocument = async (doc) => {
    setIsCancelled(false);
    setIsPending(true);
    setError(null);
    setSuccess(false);
    try {
      await addDoc(collection(db, col), doc);

      if (!isCancelled) {
        setIsPending(false);
        setSuccess(true);
      }
    } catch (err) {
      console.log(err);
      if (!isCancelled) {
        setIsPending(false);
        setError(err.message);
      }
    }
  };

  const updateDocument = async (id, update) => {
    setIsCancelled(false);
    setIsPending(true);
    setError(null);
    setSuccess(false);
    const docRef = doc(db, col, id);
    try {
      await updateDoc(docRef, update);
      if (!isCancelled) {
        setIsPending(false);
        setSuccess(true);
      }
    } catch (err) {
      console.log(err);
      if (!isCancelled) {
        setIsPending(false);
        setError(err.message);
      }
    }
  };

  const deleteDocument = async (id) => {
    setIsCancelled(false);
    setIsPending(true);
    setError(null);
    setSuccess(false);
    const ref = doc(db, col, id);
    try {
      await deleteDoc(ref);
      if (!isCancelled) {
        setIsPending(false);
        setSuccess(true);
      }
    } catch (err) {
      if (!isCancelled) {
        setIsPending(false);
        setError(err.message);
      }
    }
  };
  useEffect(() => {
    return () => {
      setIsCancelled(true);
    };
  }, []);
  return {
    addDocument,
    isPending,
    error,
    isCancelled,
    success,
    updateDocument,
    deleteDocument,
  };
};

export default useFirestore;
