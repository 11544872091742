import React from "react";
import { Col, Row } from "react-bootstrap";

import classes from "./MidasTouch.module.css";
import { shortenAddress } from "../../utils/shortenAddress";
import useFirestore from "../../Hooks/useFirestore";
import Loader from "../../UI/Loader";
import BigNumber from "bignumber.js";
import { useRecoilValue } from "recoil";
import { goldPriceState } from "../../services/atoms";
import fixNum from "../../utils/FixNumbers";
import ghold5 from "../../assets/gold_row_5.png";
import ghold10 from "../../assets/gold_row_10.png";
import ghold20 from "../../assets/gold_row_20.png";
import ghold50 from "../../assets/gold_row_50.png";
import { useState } from "react";
import MidasClassModal from "./MidasClassModal";

const TouchedItem = ({ imageUrl, tokenName, address, tokenId, value, id }) => {
  const { deleteDocument, isPending, success, error } = useFirestore("midas");
  const goldPrice = useRecoilValue(goldPriceState);

  const [showModal, setShowModal] = useState(false);

  const clickHandler = async () => {
    await deleteDocument(id);
  };
  return (
    <Row
      className={`${classes.item} d-flex justify-content-between my-1 rounded p-2 border-1 align-items-center w-100`}
    >
      <Col sm="12" md="2">
        <div className=" d-flex align-items-center">
          <p className="d-md-none me-2">NFT:</p>
          <p className={`tokenTa ${classes.nft}`}>
            <img src={imageUrl} width="40" className="me-1" alt="logo" />
            {tokenName}
          </p>
        </div>
      </Col>
      <Col sm="12" md="2">
        <div className="d-flex">
          <p className="me-2 d-md-none">Address:</p>
          <p>
            <a
              href={`https://etherscan.io/address/${address}`}
              className={`${classes.link} text-white text-decoration-none`}
              target={"_blank"}
              rel="noreferrer"
            >
              {shortenAddress(address)}
            </a>
          </p>
        </div>
      </Col>
      {/* 
      <Col sm="12" md="2">
        <div className="d-flex">
          <p className="me-2 d-md-none">Class:</p>

          <p className={`tokenTa ${classes.nft}`} style={{ minWidth: "200px" }}>
            <img
              src={
                value === 5
                  ? ghold5
                  : value === 10
                  ? ghold10
                  : value === 20
                  ? ghold20
                  : ghold50
              }
              alt={`${value} Ghold NFT`}
              width="40"
            />
            {` ${value} Ghold NFT`}
          </p>
        </div>
      </Col> */}
      <Col sm="12" md="2">
        <div className="d-flex">
          <p className="me-2 d-md-none">Value:</p>
          {goldPrice.success && (
            <p>{`${value} (${new BigNumber(value.toString())
              .multipliedBy(new BigNumber(goldPrice.value.toString()))
              .toNumber()
              .toFixed(
                fixNum(
                  new BigNumber(value.toString())
                    .multipliedBy(new BigNumber(goldPrice.value.toString()))
                    .toNumber()
                )
              )}$)`}</p>
          )}
        </div>
      </Col>
      <Col sm="12" md="2" className="d-flex justify-content-center">
        {" "}
        <button
          className={` btnConn ${classes.btn} mx-auto d-inline-block px-2 my-1`}
          onClick={clickHandler}
        >
          {isPending ? (
            <span>
              <span className="d-inline-block me-1">Pending</span>{" "}
              <Loader width={"20"} height={"20"} />
            </span>
          ) : (
            "Remove Gold"
          )}
        </button>
      </Col>
      <Col sm="12" md="2" className="d-flex justify-content-center">
        {" "}
        <button
          className={` btnConn ${classes.btn} mx-auto d-inline-block px-2 my-1`}
          onClick={() => {
            setShowModal(true);
          }}
        >
          Add Gold Value
        </button>
        {showModal && (
          <MidasClassModal
            updating={"true"}
            show={showModal}
            onHide={() => {
              setShowModal(false);
            }}
            {...{ tokenId, value, id }}
          />
        )}
      </Col>
    </Row>
  );
};

export default TouchedItem;
