import React from "react";
import { Col, Row } from "react-bootstrap";
import classes from "./MidasTouch.module.css";
import MidasClassModal from "./MidasClassModal";
import { useState } from "react";
import { shortenAddress } from "../../utils/shortenAddress";
const MidasItem = ({ name, imageUrl, description, address, id }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Row
      className={`${classes.item} d-flex justify-content-between my-1 rounded p-2 border-1 align-items-center w-100`}
    >
      <Col sm="12" md="2">
        <div className=" d-flex align-items-center">
          <p className="d-md-none me-2">Token:</p>
          <p className={`tokenTa ${classes.nft}`}>
            <img src={imageUrl} width="40" className="me-1" alt="logo" />
            {name}
          </p>
        </div>
      </Col>
      <Col sm="12" md="2">
        <div className="d-flex">
          <p className="me-2 d-md-none">Address:</p>
          <p>
            <a
              href={`https://etherscan.io/address/${address}`}
              className={`${classes.link} text-white text-decoration-none`}
              target={"_blank"}
              rel="noreferrer"
            >
              {shortenAddress(address)}
            </a>
          </p>
        </div>
      </Col>

      <Col sm="12" md="2">
        <div className="d-flex">
          <p className="me-2 d-md-none">Token ID:</p>
          <p>{id}</p>
        </div>
      </Col>
      <Col sm="12" md="2" className="d-flex justify-content-center">
        {" "}
        <button
          className={` btnConn ${classes.btn} mx-auto d-inline-block px-2`}
          onClick={() => {
            setShowModal(true);
          }}
        >
          Add Gold Value
        </button>
        {showModal && (
          <MidasClassModal
            show={showModal}
            {...{ name, image: imageUrl, address, id, name }}
            onHide={() => {
              setShowModal(false);
            }}
          />
        )}
      </Col>
    </Row>
  );
};

export default MidasItem;
