import { gql } from "apollo-boost";


export const getStakingHoldersList = gql`
query stakingHoldersList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!){
  users(first: $limit, skip: $offset , orderBy: $orderBy,orderDirection: $orderDirection) {
    id
    numberTokensStaked
    poolParticipation
  }
  contracts{
    usersCount
    totalPoolValue
  }
        
}`


export const itemsForSale = gql`
query itemsList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!,$filter:String!,$minPrice:String!,$maxPrice:String!,$category:String!){
  nfts(first: $limit, skip: $offset ,orderBy: $orderBy,orderDirection: $orderDirection,
  where: {
    currentSeller_contains: $filter,
    availableForSale:true,
      tokenClass_ : { 
        price_gte:$minPrice,
        price_lte:$maxPrice
     } 
  }
  ) {
    id
    tokenId
    itemId
    tokenURI
    contract {
      id
      numberTokensListed
    }
    updatedAt
    currentSeller
    currentOwner
    availableForSale
    tokenClass {
      id
      price
    }
  }
}
`
export const itemsForSaleByCategory = gql`
query itemsList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!,$filter:String!,$minPrice:String!,$maxPrice:String!,$category:String!){
  nfts(first: $limit, skip: $offset ,orderBy: $orderBy,orderDirection: $orderDirection,
  where: {
    tokenClass:$category,
    currentSeller_contains: $filter,
    availableForSale:true,
      tokenClass_ : { 
        price_gte:$minPrice,
        price_lte:$maxPrice
     } 
  }
  ) {
    id
    tokenId
    itemId
    tokenURI
    contract {
      id
      numberTokensListed
    }
    updatedAt
    currentSeller
    currentOwner
    availableForSale
    tokenClass{
      id
      price
    }
  }
}
`
export const myTokens = gql`
query itemsList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!,$filter:String!,$category:String!,$account:String!){
  tokens(first: $limit, skip: $offset ,orderBy: $orderBy,orderDirection: $orderDirection,where: {
    owner:$account
  }) {
    id
    tokenId
    tokenURI
    tokenClass
    contract {
      id
    }
    owner {
      id
    }
  }
}
`
export const myTokensByCategory = gql`
query itemsList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!,$filter:String!,$category:String!,$account:String!){
  tokens(first: $limit, skip: $offset ,orderBy: $orderBy,orderDirection: $orderDirection,where: {
    tokenClass:$category,
    owner:$account
  }) {
    id
    tokenId
    tokenURI
    tokenClass
    contract {
      id
    }
    owner {
      id
    }
  }
}
`
export const myTokensCategoryContains = gql`
query itemsList($offset:Int!,$limit:Int!,$orderBy:String!,$orderDirection:String!,$filter:String!,$category:String!,$account:String!){
  tokens(first: $limit, skip: $offset ,orderBy: $orderBy,orderDirection: $orderDirection,where: {
    tokenClass_contains:$category,
    owner:$account
  }) {
    id
    tokenId
    tokenURI
    tokenClass
    contract {
      id
    }
    owner {
      id
    }
  }
}
`
export const myTokenDetails = gql`
query tokenDetails($tokenId:Int!,$contractAddress:String!){
  tokens(first: 1, skip: 0,where: {
    tokenId:$tokenId,
    contract:$contractAddress
  }) {
    id
    tokenId
    tokenURI
    tokenClass
    contract {
      id
    }
    owner {
      id
    }
  }
}
`

export const marketplaceItemById = gql`
query marketplaceItemById($itemId:Int!){
  nfts(first: 1, skip: 0,where: {
    itemId:$itemId
  }) {
    id
    tokenId
    itemId
    tokenURI
    contract {
      id
    }
    updatedAt
    currentSeller
    currentOwner
    availableForSale
    tokenClass{
      id
      price
    }
  }
}
`
export const marketplaceContractInfos = gql`
 {
  contracts(first: 2) {
    id
    name
    symbol
    tradesNumber
    numberTokensListed
  }
}
`
export const tokenCategoriesList = gql`
 {
  tokenClasses(first:999){
    id
    numberTokensListed
    name
    price
  }
}
`




