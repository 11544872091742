import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { db } from "../firebase/firebaseConfig";

const useCollection = (col, _q, _or) => {
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [documents, setDocuments] = useState(null);

  const q = useRef(_q).current;
  const or = useRef(_or).current;
  useEffect(() => {
    let ref = collection(db, col);

    if (_q) {
      ref = query(ref, where(...q));
    }
    if (_or) {
      ref = query(ref, orderBy(...or));
    }
    const unsub = onSnapshot(ref, (snapshot) => {
      if (snapshot.empty) {
        setError("there is no data");
        setIsPending(false);
      }
      let result = [];
      snapshot.docs.forEach((doc) => {
        result.push({ id: doc.id, ...doc.data() });
      });
      setDocuments(result);
      setIsPending(false);
      setError(null);
    });

    return () => {
      unsub();
    };
  }, [q, col, or]);

  return { isPending, documents, error };
};

export default useCollection;
