import { ethers } from 'ethers'
import React, { useState } from 'react'
import { Button, Spinner, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ERC20Abi } from '../artifacts/contracts-abis/ERC20-abi'
import { MarketplaceAbi } from '../artifacts/contracts-abis/Marketplace-abi'
import { AppParams } from '../config'
import { pendingTransactions, accountState, referralAddressState } from '../services/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'

import { networks } from '../config'
import ReferralModal from './ReferralModal'

const changeNetwork = async ({ networkName, setError }) => {
  try {
    if (!window.ethereum) throw new Error('No crypto wallet found')
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [
        {
          ...networks[networkName],
        },
      ],
    })
  } catch (err) {
    setError(err.message)
  }
}

const BuyNFT = (props) => {
  const [isConnecting, setIsConnecting] = useState(false)
  const [error, setError] = useState()
  const [isAvalanche, setIsAvalanche] = useState(false)
  const [referralModalOpened, setReferralModalOpened] = useState(false)
  const [provider, setProvider] = useState(window.ethereum)
  const [purchaseErrorMessage, setPurchaseErrorMessage] = useState('')
  const [waiting, setWaiting] = useState(null)
  const [approving, setApproving] = useState(null)
  const [pendingWaiting, setPendingWaiting] = useState(null)
  const connectWalletMetamaskExist = async () => {
    localStorage.setItem('loggedOut', 'false')
    try {
      //   if (!window.ethereum) {
      //     setIsMetamaskAlertOpened(true)
      //     return
      //     //return alert('Please install metamask')
      //   }

      // Loading...
      setIsConnecting(true)
      // Get all the accounts and the user will be able to choose one.
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })

      // Loading...
      setIsConnecting(false)
      setAccount(accounts[0])
      // Test provider avalanche
      console.log(provider.chainId)
      if (provider.chainId !== AppParams.AppChainId) {
        await handleNetworkSwitch(AppParams.AppChainId == '0xa86a' ? 'etheurem' : 'goerli')
        // setIsAvalanche(false)
      } else {
        setIsAvalanche(true)
      }
      console.log('isAvalanche: ', isAvalanche)

      window.ethereum.on('accountsChanged', function (accounts) {
        console.log('accountChaned', accounts)
        setAccount(accounts[0])
      })
    } catch (error) {
      console.log(error)
      throw new Error('No ethereum object.')
    }
  }
  const handleNetworkSwitch = async (networkName) => {
    setError()
    await changeNetwork({ networkName, setError })
  }

  const connectWalletMetamaskExistNotExist = () => {
    if (!window.ethereum) {
      setIsMetamaskAlertOpened(true)
      return
      //return alert('Please install metamask')
    }
  }

  const [pendings, setPendings] = useRecoilState(pendingTransactions)

  const { nftContract, itemId, price, avaxPayement } = props.props
  const navigate = useNavigate()

  const [isMetamaskAlertOpened, setIsMetamaskAlertOpened] = useState(false)
  const [account, setAccount] = useRecoilState(accountState)

  const referralAddress = useRecoilValue(referralAddressState)



  const verifyReferrals = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    //const provider = new ethers.providers.Web3Provider(connection)

    const signer = provider.getSigner()

    const contract = new ethers.Contract(
      AppParams.MARKET_ADDRESS,
      MarketplaceAbi,
      signer
    )

    if (account == '') {
      setIsMetamaskAlertOpened(true)
      return
    }
    setWaiting(itemId)
    let referral = await contract.getReferral(account)
    
    console.log(referral)
    //todo: change with getReferral
    if (referral === '0x0000000000000000000000000000000000000000') {
      setReferralModalOpened(true)
      return;
    } else {

    }
  }


  async function buyNft(referral =null) {
    setWaiting(null)
    if (account == '') {
      setIsMetamaskAlertOpened(true)
      return
    }


    const provider = new ethers.providers.Web3Provider(window.ethereum)
    //const provider = new ethers.providers.Web3Provider(connection)

    const signer = provider.getSigner()

    const contract = new ethers.Contract(
      AppParams.MARKET_ADDRESS,
      MarketplaceAbi,
      signer
    )

    const valueToSend = ethers.utils.parseUnits(price.toString(), 'ether')

    const contractErc20 = new ethers.Contract(
      AppParams.ANT_ERC20_CONTRACT_ADDRESS,
      ERC20Abi,
      signer
    )

    let balanceOf = await contractErc20.balanceOf(account);
    if (parseInt(valueToSend) > parseInt(balanceOf)) {
      setPurchaseErrorMessage(
        'You do not have the necessary ' + AppParams.PAYEMENT_TOKEN_NAME + ' tokens in your balance to continue the purchase.'
      )
      return;
    }

    let allowanceRes = await contractErc20.allowance(account, AppParams.MARKET_ADDRESS);
    console.log("valueToSend | allowance", parseInt(valueToSend), parseInt(allowanceRes))
    if (parseInt(valueToSend) > parseInt(allowanceRes)) {
      try {
        setApproving(itemId)
        const approveTransaction = await contractErc20.approve(
          AppParams.MARKET_ADDRESS,
          valueToSend
        )
        await approveTransaction.wait()
        setApproving(null)
      } catch (exApp) {
        setApproving(null)
        return;
      }
    }
    try {
      setPendingWaiting(itemId);
      let transaction 
      if(referral === null ){
        transaction = await contract.buyToken(
          nftContract,
          itemId
        )
      }else{
        transaction = await contract.referralBuyToken(
          nftContract,
          itemId,
          referral
        )
      }

      setPendingWaiting(null);
      let temp = [...pendings]
      temp.push({
        txId: transaction.hash,
        itemId: itemId,
        finished: false,
      })

      setPendings(temp)

      let txResult = await transaction.wait()
      navigate('/nfts')
    } catch (exPurchase) {
      setPendingWaiting(null);
      console.error(exPurchase);
    }


    // Launch popup if not connected!
    if (!window.ethereum) {
      setIsMetamaskAlertOpened(true)
      //   return
      return alert("To keep your ANT's safe use metamask wallet")
    }
  }

  return (
    <>
      {pendings.filter((el) => el.itemId === itemId).length > 0 || approving == itemId || pendingWaiting == itemId ? (
        <Button className='buy' variant='primary'>
          <Spinner
            as='span'
            animation='grow'
            size='sm'
            role='status'
            aria-hidden='true'
          />
          {approving == itemId ? 'Approving...' : 'Pending...'}
        </Button>
      ) : (
        <>
         {/* 
          <Button className='buy' variant='primary' disabled={itemId == waiting} onClick={() => verifyReferrals()}>
            {itemId == waiting ? 'Waiting...':'Buy'}
          </Button>
          
       */}
        <Button className='buy' variant='primary' onClick={() => buyNft()}>
          Buy
        </Button> 
        </>

        )}

      <Modal
        className='Imetamask pend'
        show={isMetamaskAlertOpened}
        onHide={(e) => {
          setIsMetamaskAlertOpened(false)
        }}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>Please connect your MetaMask wallet</Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          {window.ethereum &&
            'To buy NFTs, please connect your MetaMask wallet'}
          {!window.ethereum && "To keep your NFT's safe use metamask wallet"}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              setIsMetamaskAlertOpened(false)
              connectWalletMetamaskExist()
              connectWalletMetamaskExistNotExist()
            }}
          >
            {!window.ethereum && 'Ok'}
            {window.ethereum && 'Wallet Connect'}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className='Imetamask pend'
        show={purchaseErrorMessage.length > 0}
        onHide={(e) => {
          setPurchaseErrorMessage('')
        }}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header>Insufficient funds</Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          {purchaseErrorMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              setPurchaseErrorMessage('')
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <ReferralModal
        opened={referralModalOpened}
        onCancel={() => {
          setReferralModalOpened(false)
          setWaiting(null)
        }}
        onIgnoreAndContinue={() => {
          setReferralModalOpened(false)
          buyNft(null)
        }}
        onUseRefferalAndContinue={(referral) => {
          setReferralModalOpened(false)
          buyNft(referral)
        }}
      >

      </ReferralModal>
    </>
  )
}

export default BuyNFT
