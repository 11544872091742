import React from "react";

import { Container, Row } from "react-bootstrap";

import { useRecoilValue } from "recoil";
import { accountState, midasFilterTermState } from "../services/atoms";

import MidasFilter from "../components/midas/MidasFilter";
import MidasNfts from "../components/midas/MidasNfts";
import TouchedNfts from "../components/midas/TouchedNfts";

import classes from "../components/midas/MidasTouch.module.css";

const MidasTouch = () => {
  const filterTerm = useRecoilValue(midasFilterTermState);

  const account = useRecoilValue(accountState);

  return (
    <section>
      <Container>
        {account && <MidasFilter />}
        {filterTerm === "all" && account && <MidasNfts />}
        {filterTerm === "touched" && account && <TouchedNfts />}
        <Row
          className={`${classes["loader-container"]} d-flex justify-content-center align-items-center`}
        >
          {!account && (
            <p className="text-center ">
              Please connect your wallet to view all your NFTs
            </p>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default MidasTouch;
