import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase/firebaseConfig";

const useDocument = (c, id) => {
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [document, setDocument] = useState(null);

  useEffect(() => {
    const unsub = onSnapshot(
      doc(db, c, id),
      (snapshot) => {
        setDocument({ ...snapshot.data(), id: snapshot.id });
        setIsPending(false);
      },
      (err) => {
        console.log(err.message);
        setError("failed to fetch data");
        setIsPending(false);
      }
    );
    return () => {
      unsub();
    };
  }, [id, c]);
  return { document, isPending, error };
};

export default useDocument;
