import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { AppParams } from "../../config";
import { Alchemy, Network } from "alchemy-sdk";

import classes from "./MidasTouch.module.css";
import { useRecoilValue } from "recoil";
import { accountState } from "../../services/atoms";
import { useEffect } from "react";
import Loader from "../../UI/Loader";
import { Col, Row } from "react-bootstrap";
import MidasItem from "./MidasItem";
import useCollection from "../../Hooks/useCollection";

const MidasNfts = () => {
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [nfts, setNfts] = useState([]);
  const account = useRecoilValue(accountState);
  const {
    documents,
    error: fbError,
    success: fbSuccess,
    isPending,
  } = useCollection("midas", ["owner", "==", account.toLowerCase()]);

  const getNfts = useCallback(async () => {
    if (!documents) {
      return;
    }
    setIsLoading(true);
    setError("");
    setSuccess(false);
    if (fbError && !isPending) {
      setIsLoading(false);
      setError("Something went wrong, please try later");
      setSuccess(false);
      return;
    }
    try {
      const settings = {
        apiKey: "I3HH-eMusjU1Vj2LgCDjFrQtUoYnsonO",
        network: Network.ETH_GOERLI,
      };

      const alchemy = new Alchemy(settings);

      const nfts = await alchemy.nft.getNftsForOwner(account);

      setSuccess(true);
      setNfts(
        nfts.ownedNfts.filter((nft) => {
          return (
            nft.contract.address.toLowerCase() ===
              AppParams.MIDAS_CONTRACT_ADDRESS.toLowerCase() &&
            !documents.find(
              (doc) =>
                doc.id === `${nft.contract.address.toLowerCase()}${nft.tokenId}`
            )
          );
        })
      );
    } catch (error) {
      setSuccess(false);
      setError("Something went wrong, please try later");
    } finally {
      setIsLoading(false);
    }
  }, [account, fbError, fbSuccess, documents]);

  useEffect(() => {
    if (account) {
      getNfts();
    }
  }, [account, documents, getNfts]);
  return (
    <>
      {isLoading && (
        <div
          className={`${classes["loader-container"]} d-flex justify-content-center align-items-center w-100`}
        >
          <Loader width={"26"} height={26} />
        </div>
      )}
      {success && account && nfts.length !== 0 && (
        <>
          <Row className="d-flex justify-content-between my-1 rounded p-2 border-1 align-items-center w-100">
            <Col sm="2">
              <p className="d-block m-0 p-0">NFT</p>
            </Col>
            <Col sm="2">
              <p className="d-block m-0 p-0">Contract</p>
            </Col>
            <Col sm="2">
              <p className="d-block m-0 p-0">Token ID</p>
            </Col>
            <Col sm="2"></Col>
          </Row>
          {nfts.map((nft) => {
            return (
              <MidasItem
                key={nft.id}
                {...nft.rawMetadata}
                address={nft.contract.address}
                id={nft.tokenId}
              />
            );
          })}
        </>
      )}
      <Row
        className={`${classes["loader-container"]} d-flex justify-content-center align-items-center`}
      >
        {" "}
        {success && nfts.length === 0 && <p className="text-center">Empty</p>}
      </Row>
      <Row
        className={`${classes["loader-container"]} d-flex justify-content-center align-items-center`}
      >
        {" "}
        {error && <p className="text-center text-danger">{error}</p>}
      </Row>
    </>
  );
};

export default MidasNfts;
