import React from 'react'
import { Button } from 'react-bootstrap';

export default function GenerateNFTsMetas() {

    const TOKENS_TO_MINT = [
        {
            class: "O2C1",
            startFrom: 1,
            numberToMint: 50,
        },{
            class: "O2C2",
            startFrom: 51,
            numberToMint: 50
        },{
            class: "O2CPRE3S",
            startFrom: 101,
            numberToMint: 50
        },{
            class: "O2CPRE6S",
            startFrom: 151,
            numberToMint: 50
        },{
            class: "O2SDAO",
            startFrom: 201,
            numberToMint: 50
        },{
            class: "O2SLAUNCH",
            startFrom: 251,
            numberToMint: 50
        }
    ];
    
    const definition = {
        "O2C1": {
            name: "O2C1 #{tokenId}",
            image: "ipfs://QmV2traaQmeZrf3dUzdB6XQrma1Ly6oPomL1JCEqE64Lcw",
            description: "This NFT allows you to reserve 1 hour of consulting. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            pdfFile:"ipfs://QmZ18Uj9y79d3qU7PyEq98fWnnittyAN4fqXAZ8Pth6qKu",
        },
        "O2C2": {
            name: "O2C2 #{tokenId}",
            image: "ipfs://QmVTwKx6vsDZmnghqTspL2Bgidf7wWnvCj6wnuzKnz7mRw",
            description: "This NFT allows you to book 2 hours of consulting. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            pdfFile:"ipfs://QmZ18Uj9y79d3qU7PyEq98fWnnittyAN4fqXAZ8Pth6qKu",
        },
        "O2CPRE3S": {
            name: "O2CPRE3S #{tokenId}",
            image: "ipfs://QmSYXAk3mHVMyUDw7xcQeb8PRVmLX6qBM9111mCLMpU5Xx",
            description: "This NFT allows you to have a 3-week pre-study. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            pdfFile:"ipfs://QmZ18Uj9y79d3qU7PyEq98fWnnittyAN4fqXAZ8Pth6qKu",
        },
        "O2CPRE6S": {
            name: "O2CPRE6S #{tokenId}",
            image: "ipfs://QmXs8CUGsttSXnU4HvdEDtr3WUF3S723mBXXHkvMrQuwMs",
            description: "This NFT allows you to have a 6-week pre-study. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            pdfFile:"ipfs://QmZ18Uj9y79d3qU7PyEq98fWnnittyAN4fqXAZ8Pth6qKu",
        },
        "O2SDAO": {
            name: "O2SDAO #{tokenId}",
            image: "ipfs://QmbapoJm5UYytmYVRJVvySnz6qwr2UjfBhviwfTV2CzPHV",
            description: "This NFT allows you to reserve in the DAO. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            pdfFile:"ipfs://QmZ18Uj9y79d3qU7PyEq98fWnnittyAN4fqXAZ8Pth6qKu",
        },
        "O2SLAUNCH": {
            name: "O2SLAUNCH #{tokenId}",
            image: "ipfs://QmPZ4hZmejXffCcGw7U7RFG9Tu3ZJPwCUbsakxnY5nRjrd",
            description: "This NFT allows you to reserve in the Lanchpad. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            pdfFile:"ipfs://QmZ18Uj9y79d3qU7PyEq98fWnnittyAN4fqXAZ8Pth6qKu",
        }
    }


    const generateMetas = () => {
        let metas = []
        for (var i = 0; i < TOKENS_TO_MINT.length; i++) {
            for (var j = 0; j < TOKENS_TO_MINT[i].numberToMint; j++) {
                var tokenId = j + TOKENS_TO_MINT[i].startFrom;
                metas.push({
                    name: definition[TOKENS_TO_MINT[i].class].name.replace("{tokenId}", tokenId),
                    tokenId: tokenId,
                    image: definition[TOKENS_TO_MINT[i].class].image,
                    description: definition[TOKENS_TO_MINT[i].class].description,
                    pdfFile: definition[TOKENS_TO_MINT[i].class].pdfFile,
                    category:TOKENS_TO_MINT[i].class
                })
            }
        }
        zipResult(metas);
    }


    const zipResult = (metasToZip) => {
        const zip = require('jszip')();
        let files = metasToZip
        for (let file = 0; file < metasToZip.length; file++) {
            // Zip file with the file name.
            zip.file(metasToZip[file].tokenId + ".json", JSON.stringify(files[file]));
        }
        zip.generateAsync({ type: "blob" }).then(content => {
            //saveAs(content, "example.zip");
            //setFinalZipFile(content)
            console.log("ziped", content)
            const element = document.createElement("a");
            element.href = URL.createObjectURL(content);
            element.download = "myFile.zip";
            document.body.appendChild(element);
            element.click();
        });
    }

    return (
        <Button onClick={()=>{generateMetas()}}>GenerateNFTsMetas</Button>
    )
}
