import React from "react";
import { Col, Row } from "react-bootstrap";

import classes from "./Vault.module.css";
import { useState } from "react";
import SerialNumberModal from "./SerialNumberModal";
import BigNumber from "bignumber.js";

const VaultItem = ({ serialNumberl, value, name, quantity }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Row
      className={`${classes.item} d-flex justify-content-between my-1 rounded p-2 border-1 align-items-center w-100`}
    >
      <Col sm="12" md="2">
        <div className=" d-flex">
          <p className="d-md-none me-2">Product:</p>
          <p className={`tokenTa`}>{name}</p>
        </div>
      </Col>
      <Col sm="12" md="2">
        <div className="d-flex">
          <p className="me-2 d-md-none">Quantity:</p>
          <p>{`x${quantity}`}</p>
        </div>
      </Col>
      <Col sm="12" md="2">
        <div className="d-flex d-md-block">
          <p className="me-2 d-md-none">Total:</p>
          <p className="d-md-flex justify-content-center align-items-center">
            {`${new BigNumber(quantity.toString())
              .multipliedBy(parseFloat(value).toString())
              .toString()} g`}
          </p>
        </div>
      </Col>
      <Col sm="12" md="2">
        <button
          className={`${classes.btn} btnConn p-1 px-2`}
          onClick={() => {
            setShowModal(true);
          }}
        >
          Show Serial Numbers
        </button>
        {/* {showModal && (
          <SerialNumberModal
            show={showModal}
            onHide={() => {
              setShowModal(false);
            }}
            serials={serialNumbers}
          />
        )} */}
      </Col>
    </Row>
  );
};

export default VaultItem;
