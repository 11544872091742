const numberFixDecimals = (value) => {
  let num;
  if (typeof value === "string") {
    num = +value;
  }
  num = value;
  if (num === 0) return 0;
  if (num >= 1) {
    return 2;
  } else if (num >= 0.1 && num < 1) {
    return 4;
  } else if (num >= 0.01 && num < 0.1) {
    return 5;
  } else if (num < 0.01 && num >= 0.001) {
    return 6;
  } else if (num < 0.001 && num >= 0.0001) {
    return 7;
  } else if (num < 0.0001 && num >= 0.00001) {
    return 8;
  } else if (num < 0.00001 && num >= 0.000001) {
    return 9;
  } else if (num < 0.000001 && num >= 0.0000001) {
    return 10;
  } else if (num < 0.0000001 && num >= 0.0000001) {
    return 11;
  } else if (num < 0.00000001 && num >= 0.000000001) {
    return 12;
  } else {
    return 13;
  }
};

export default numberFixDecimals;
