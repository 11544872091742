import React, { useEffect, useState } from "react";

import { Button, Card } from "react-bootstrap";

import { ethers } from "ethers";

import { AppParams } from "../config";

import { useRecoilValue } from "recoil";
import { accountState, goldPriceState } from "../services/atoms";
import BigNumber from "bignumber.js";
import Loader from "../UI/Loader";
import useHttp from "../Hooks/useHttp";
import secret from "../secret.json";
import MessageModal from "../components/marketplace/MessageModal";
import { db } from "../firebase/firebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";
import fixNumber from "../utils/FixNumbers";

const NFTCard = ({ name, imageURL, price, totalSupply, currentSupply }) => {
  const account = useRecoilValue(accountState);
  const [purchaseState, setPurchaseState] = useState({
    isLoading: false,
    success: false,
    error: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const { sendRequest } = useHttp();
  const goldPrice = useRecoilValue(goldPriceState);

  const clickHandler = async () => {
    if (!account) return;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    //const provider = new ethers.providers.Web3Provider(connection)
    setPurchaseState((prevSate) => {
      return { ...prevSate, isLoading: true, success: false, error: "" };
    });
    const signer = provider.getSigner(account);
    const nftContract = new ethers.Contract(
      AppParams.NFT_CONTRACT_ADDRESS,
      AppParams.NFT_CONTRACT_ABI,
      signer
    );
    const paymentTokenContract = new ethers.Contract(
      AppParams.PAYEMENT_TOKEN_ADDRESS,
      AppParams.PAYEMENT_TOKEN_ABI,
      signer
    );
    const goldPrice = await nftContract.goldPrice();
    let priceBN;
    let tokenMetadata;
    setMessage("");
    setShowModal(false);
    try {
      await sendRequest(
        {
          method: "post",
          url: "https://api.pinata.cloud/pinning/pinJSONToIPFS",
          data: JSON.stringify({
            categoryName: name,
            imageURL,
            initialSupply: totalSupply,
            value: parseFloat(name),
          }),
          headers: {
            Authorization: `Bearer ${secret.PINATA_JWT}`,
            "Content-Type": "application/json",
          },
        },
        (data) => {
          tokenMetadata = data.IpfsHash;
        }
      );
    } catch (error) {
      setPurchaseState((prevSate) => {
        return {
          ...prevSate,
          isLoading: false,
          success: false,
          error: "Failed to add token metadata to IPFS",
        };
      });
      return;
    }

    const docRef = doc(db, "vault", "vault");
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();

    switch (name.toLowerCase()) {
      case "5 ghold":
        try {
          priceBN = new BigNumber(goldPrice.toString())
            .multipliedBy(new BigNumber("5"))
            .multipliedBy(
              new BigNumber("10").pow(
                AppParams.PAYEMENT_TOKEN_DECIMALS.toString()
              )
            );
          const allowed = await paymentTokenContract.allowance(
            account,
            AppParams.NFT_CONTRACT_ADDRESS
          );

          if (
            priceBN
              .dividedBy(
                new BigNumber("10").pow(
                  new BigNumber(AppParams.PAYEMENT_TOKEN_DECIMALS.toString())
                )
              )
              .toNumber() >
            new BigNumber(allowed.toString())
              .dividedBy(
                new BigNumber("10").pow(
                  new BigNumber(AppParams.PAYEMENT_TOKEN_DECIMALS.toString())
                )
              )
              .toNumber()
          ) {
            const tx = await paymentTokenContract.approve(
              AppParams.NFT_CONTRACT_ADDRESS,
              `0x${priceBN.toString(16)}`
            );

            await tx.wait();
          }

          const tx2 = await nftContract.mint5Ghold(tokenMetadata);
          await tx2.wait();

          setPurchaseState((prevSate) => {
            return {
              ...prevSate,
              isLoading: false,
              success: true,
            };
          });
          setShowModal(true);
          setMessage(`${name} NFT successfully bought`);
        } catch (error) {
          setPurchaseState((prevSate) => {
            return {
              ...prevSate,
              isLoading: false,
              success: false,
              error: "Something went wrong, please try later",
            };
          });
          return;
        }
        try {
          await setDoc(docRef, {
            ...data,
            nftsGold: data.nftsGold + 5,
            bullion1: {
              ...data.bullion1,
              quantity: data.bullion1.quantity + 5,
            },
          });
        } catch (error) {
          console.log("Error in updating vault");
        }
        break;
      case "10 ghold":
        try {
          priceBN = new BigNumber(goldPrice.toString())
            .multipliedBy(new BigNumber("10"))
            .multipliedBy(
              new BigNumber("10").pow(
                AppParams.PAYEMENT_TOKEN_DECIMALS.toString()
              )
            );
          const allowed = await paymentTokenContract.allowance(
            account,
            AppParams.NFT_CONTRACT_ADDRESS
          );

          if (
            priceBN
              .dividedBy(
                new BigNumber("10").pow(
                  new BigNumber(AppParams.PAYEMENT_TOKEN_DECIMALS.toString())
                )
              )
              .toNumber() >
            new BigNumber(allowed.toString())
              .dividedBy(
                new BigNumber("10").pow(
                  new BigNumber(AppParams.PAYEMENT_TOKEN_DECIMALS.toString())
                )
              )
              .toNumber()
          ) {
            const tx = await paymentTokenContract.approve(
              AppParams.NFT_CONTRACT_ADDRESS,
              `0x${priceBN.toString(16)}`
            );

            await tx.wait();
          }

          const tx2 = await nftContract.mint10Ghold(tokenMetadata);
          await tx2.wait();

          setPurchaseState((prevSate) => {
            return {
              ...prevSate,
              isLoading: false,
              success: true,
            };
          });
          setShowModal(true);
          setMessage(`${name} NFT successfully bought`);
        } catch (error) {
          setPurchaseState((prevSate) => {
            return {
              ...prevSate,
              isLoading: false,
              success: false,
              error: "Something went wrong, please try later",
            };
          });
          return;
        }
        try {
          await setDoc(docRef, {
            ...data,
            nftsGold: data.nftsGold + 10,
            bullion10: {
              ...data.bullion10,
              quantity: data.bullion10.quantity + 1,
            },
          });
        } catch (error) {
          console.log("Error in updating vault");
        }
        break;
      case "20 ghold":
        try {
          priceBN = new BigNumber(goldPrice.toString())
            .multipliedBy(new BigNumber("20"))
            .multipliedBy(
              new BigNumber("10").pow(
                AppParams.PAYEMENT_TOKEN_DECIMALS.toString()
              )
            );
          const allowed = await paymentTokenContract.allowance(
            account,
            AppParams.NFT_CONTRACT_ADDRESS
          );

          if (
            priceBN
              .dividedBy(
                new BigNumber("10").pow(
                  new BigNumber(AppParams.PAYEMENT_TOKEN_DECIMALS.toString())
                )
              )
              .toNumber() >
            new BigNumber(allowed.toString())
              .dividedBy(
                new BigNumber("10").pow(
                  new BigNumber(AppParams.PAYEMENT_TOKEN_DECIMALS.toString())
                )
              )
              .toNumber()
          ) {
            const tx = await paymentTokenContract.approve(
              AppParams.NFT_CONTRACT_ADDRESS,
              `0x${priceBN.toString(16)}`
            );

            await tx.wait();
          }

          const tx2 = await nftContract.mint20Ghold(tokenMetadata);
          await tx2.wait();

          setPurchaseState((prevSate) => {
            return {
              ...prevSate,
              isLoading: false,
              success: true,
            };
          });
          setShowModal(true);
          setMessage(`${name} NFT successfully bought`);
        } catch (error) {
          setPurchaseState((prevSate) => {
            return {
              ...prevSate,
              isLoading: false,
              success: false,
              error: "Something went wrong, please try later",
            };
          });
          return;
        }
        try {
          await setDoc(docRef, {
            ...data,
            nftsGold: data.nftsGold + 20,
            bullion20: {
              ...data.bullion20,
              quantity: data.bullion20.quantity + 1,
            },
          });
        } catch (error) {
          console.log("Error in updating vault");
        }
        break;
      case "50 ghold":
        try {
          priceBN = new BigNumber(goldPrice.toString())
            .multipliedBy(new BigNumber("50"))
            .multipliedBy(
              new BigNumber("10").pow(
                AppParams.PAYEMENT_TOKEN_DECIMALS.toString()
              )
            );
          const allowed = await paymentTokenContract.allowance(
            account,
            AppParams.NFT_CONTRACT_ADDRESS
          );

          if (
            priceBN
              .dividedBy(
                new BigNumber("10").pow(
                  new BigNumber(AppParams.PAYEMENT_TOKEN_DECIMALS.toString())
                )
              )
              .toNumber() >
            new BigNumber(allowed.toString())
              .dividedBy(
                new BigNumber("10").pow(
                  new BigNumber(AppParams.PAYEMENT_TOKEN_DECIMALS.toString())
                )
              )
              .toNumber()
          ) {
            const tx = await paymentTokenContract.approve(
              AppParams.NFT_CONTRACT_ADDRESS,
              `0x${priceBN.toString(16)}`
            );

            await tx.wait();
          }

          const tx2 = await nftContract.mint50Ghold(tokenMetadata);
          await tx2.wait();

          setPurchaseState((prevSate) => {
            return {
              ...prevSate,
              isLoading: false,
              success: true,
            };
          });
          setShowModal(true);
          setMessage(`${name} NFT successfully bought`);
        } catch (error) {
          setPurchaseState((prevSate) => {
            return {
              ...prevSate,
              isLoading: false,
              success: false,
              error: "Something went wrong, please try later",
            };
          });
          return;
        }
        try {
          await setDoc(docRef, {
            ...data,
            nftsGold: data.nftsGold + 50,
            bullion50: {
              ...data.bullion50,
              quantity: data.bullion50.quantity + 1,
            },
          });
        } catch (error) {
          console.log("Error in vault updating");
        }
        break;
      default:
        throw new Error(`${name.toLowerCase()} is unhandled case`);
    }
  };
  return (
    <Card className="my-3 nftC">
      <Card.Body>
        <div style={{ display: "flex" }}>
          <div>
            {/* <span className="CardTokenId" onClick={showOnExplorer}>
              #{nft.tokenId}
            </span> */}
          </div>
          {/*<div className="CardTokenTokenClass">
            <div className="codeD mx-auto">name</div>
          </div>*/}
        </div>
        {/*  <div className="titleN" style={{ paddingTop: 10 }}>
         <Link to={`/nft/${nft.tokenId}/${nft.itemId}/${nft.contract.id}`}>
            <Card.Title as="div">
              <span>{item.name}</span>
            </Card.Title>
          </Link> 
        </div>*/}

        {/* <Link> */}
        <Card.Img variant="top" src={imageURL} />
        {/* </Link> */}
        <div className="positionDe">
          <div className="AlignBc text-center">
            <Card.Text as="h3">
              {/* {circumference(nft.tokenClass.price)
                .toLocaleString()
                .replace(",", ".")}{" "}
              {AppParams.PAYEMENT_TOKEN_NAME} */}
              {goldPrice.success &&
                new BigNumber(parseFloat(name))
                  .multipliedBy(new BigNumber(goldPrice.value.toString()))
                  .toFixed(
                    fixNumber(
                      new BigNumber(parseFloat(name))
                        .multipliedBy(new BigNumber(goldPrice.value.toString()))
                        .toString()
                    )
                  )
                  .toString()}{" "}
              USDT
            </Card.Text>
          </div>
          <div className="AlignBc">
            {/* <BuyNFT
              props={{
                nftContract: nft.contract.id,
                itemId: nft.itemId,
                price: nft.tokenClass.price,
                avaxPayement: false,
              }}
            ></BuyNFT> */}
            <Button
              className="buy"
              variant="primary"
              onClick={clickHandler}
              disabled={purchaseState.isLoading}
            >
              {purchaseState.isLoading ? (
                <span>
                  <span className="d-inline-block me-1">Pending</span>{" "}
                  <Loader width={"20"} height={"20"} />
                </span>
              ) : (
                "Buy"
              )}
            </Button>
          </div>
        </div>
      </Card.Body>
      <MessageModal
        show={showModal}
        message={message}
        onHide={() => {
          setShowModal(false);
          setMessage("");
        }}
      />
    </Card>
  );
};

export default NFTCard;
