import React, { useState, useEffect } from 'react'

import { Row, Col, Button } from 'react-bootstrap'

import AssetCard from './AssetCard'

import { TokensClient } from '../config'
import { myTokens, myTokensByCategory } from '../services/graphql'

import { useRecoilState } from 'recoil';
import { accountState, refreshIndex } from '../services/atoms'
import { Link } from 'react-router-dom'
import TokenClassFilter from '../components/TokenClassFilter'

const MyAssets = () => {
  const [nfts, setNfts] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded')
  const [category, setCategory] = useState('')
  const [nftsList, setNftsList] = useState({ data: [] })

  // Search Filter
  const [filterInput, setFilterInput] = useState('')
  const [selectedOrder, setSelectedOrder] = useState('id-desc')
  
  const [account] = useRecoilState(accountState);
  const [refresh, setRefresh] = useRecoilState(refreshIndex);
  

  const remoteData = (query) => {
    const queryCall = query.category == '' ? myTokens : myTokensByCategory;
    return TokensClient.query({
      query: queryCall,
      fetchPolicy: 'network-only',
      variables: {
        account: query.account,
        category: query.category,
        filter: query.filter,
        offset: query.page * query.pageSize,
        limit: query.pageSize,
        orderBy: query.orderBy === undefined ? "id" : query.orderBy,
        orderDirection: query.orderDirection === undefined || query.orderDirection === "" ? "desc" : query.orderDirection
      }
    }).then((res) => {
      setNftsList({ data: res.data.tokens })
    })
  }

  

  useEffect(async () => {
    remoteData(
      {
        account: account,
        category: category,
        filter: filterInput,
        page: 0,
        pageSize: 999,
        orderBy: selectedOrder.split("-")[0],
        orderDirection: selectedOrder.split("-")[1]
      }
    );
  }, [filterInput, selectedOrder, category, account, refresh])

  if (loadingState === 'loaded' && !nfts.length)
    return <h1 className='px-20 py-10 text-3xl'>No Assets Owned</h1>
  return (
    <div>
      <div className="AliCat">
        <TokenClassFilter onSelect={(value) => setCategory(value)}></TokenClassFilter>
      </div>
      <Row>
        {
          nftsList.data.length > 0 ?
            nftsList.data.map((nft, index) => (
              <Col key={index} sm={12} md={6} lg={4} xl={3}>
                <AssetCard nft={nft} />
              </Col>
            ))
            :
            <div style={{ height: '150px', lineHeight: '150px', textAlign: 'center' ,zIndex:1 ,color:'#fff'}}>
              <span style={{ verticalAlign: 'middle', fontSize: 18 }}>No assets yet. Get your first NFT from <Link to={category != '' ? "/nfts?category="+category : "/nfts"}>here</Link></span>
            </div>

        }
      </Row>
    </div>
  )
}

export default MyAssets
