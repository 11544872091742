import React from "react";
import history from "../../data/erc20PurchaseHistory.json";
import { Col, Row } from "react-bootstrap";
import HistoryItem from "./HistoryItem";
import { AppParams } from "../../config";

const ERC20PurchaseHistory = () => {
  return (
    <>
      <Row>
        <h2 className="text-center my-3">
          {" "}
          Ghold purchase history
        </h2>
      </Row>
      <Row className="d-flex justify-content-between my-1 rounded p-2 border-1 align-items-center w-100">
        <Col sm="2">
          <p className="d-block m-0 p-0">Date</p>
        </Col>
        <Col sm="2">
          <p className="d-block m-0 p-0">Amount</p>
        </Col>
        <Col sm="2">
          <p className="d-block m-0 p-0">{`price (${AppParams.PAYEMENT_TOKEN_NAME})`}</p>
        </Col>
        <Col sm="2"></Col>
      </Row>
      {history.map((pur) => {
        return <HistoryItem {...pur} key={pur.id} />;
      })}
    </>
  );
};

export default ERC20PurchaseHistory;
