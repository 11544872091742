import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import NFTDetails from "./screens/NFTDetails";
import Nfts from "./screens/Nfts";
import CreateItem from "./screens/CreateItem";
import MyAssets from "./screens/MyAssets";
import CreatorDashboard from "./screens/CreatorDashboard";
import CreateAsset from "./screens/CreateAsset";
import MyAssetDetails from "./screens/MyAssetDetails";
import BatchCreateItems from "./screens/BatchCreateItems";
import BatchCreateEquipements from "./screens/BatchCreateEquipements";
import BatchCreateReducer from "./screens/BatchCreateReducer";
import Wallet from "./screens/Wallet";
import Vault from "./screens/Vault";
import MidasTouch from "./screens/MidasTouch";

const App = () => {
  return (
    <Router>
      <>
        <Header />
        <main>
          <Container>
            <Routes>
              <Route path="/" element={<Nfts />} exact />
              <Route path="/product/:id" element={<ProductScreen />} />
              <Route
                path="/nft/:token_id/:item_id/:contractAddress"
                element={<NFTDetails />}
              />
              <Route path="/nfts" element={<Nfts />} />
              <Route path="create-item" element={<CreateItem />} />
              <Route path="batch-create-item" element={<BatchCreateItems />} />
              <Route
                path="batch-create-equipement"
                element={<BatchCreateEquipements />}
              />
              <Route
                path="batch-create-reducers"
                element={<BatchCreateReducer />}
              />
              <Route path="create-asset" element={<CreateAsset />} />
              <Route path="creator-dashboard" element={<CreatorDashboard />} />
              <Route
                path="/my-assets/:token_id/:contractAddress"
                element={<MyAssetDetails />}
              />
              <Route path="wallet" element={<Wallet />} />
              <Route path="/midas_touch" element={<MidasTouch />} />
              {/*
                <Route path='staking' element={<Staking />} />
                <Route path='pool-holder' element={<PoolHolder />} />
              */}
              <Route path="/vault" element={<Vault />} />
            </Routes>
          </Container>
        </main>
        <Footer />
      </>
    </Router>
  );
};

export default App;
