import BigNumber from "bignumber.js";

export default function calculateAmountWithFees(amount, fees, feesDecimals) {
  const amountBN = new BigNumber(amount.toString());
  const amountFeesBN = new BigNumber(amount.toString())
    .multipliedBy(new BigNumber(fees.toString()))
    .dividedBy(new BigNumber("10").pow(feesDecimals))
    .dividedBy("100");
  const totalAmountBN = amountBN.plus(amountFeesBN);

  return totalAmountBN;
}
