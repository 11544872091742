import React, { useCallback } from "react";
import { useState } from "react";
import { AppParams, currentMode } from "../config";
import { ethers } from "ethers";
import { useRecoilValue, useRecoilState } from "recoil";
import { accountState, goldPriceState } from "../services/atoms";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";
import BigNumber from "bignumber.js";
import calculateAmountWithFees from "../utils/CalculateFees";
import MessageModal from "../components/marketplace/MessageModal";
import bullionRepartition from "../utils/bullionRepartition";
import useFirestore from "../Hooks/useFirestore";
import { db } from "../firebase/firebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";
import useHttp from "../Hooks/useHttp";
import secret from "../secret.json";
import fixNum from "../utils/FixNumbers";
import useGetGoldPrice from "../Hooks/useGetGoldPrice";
import numberOfDecimals from "../utils/numberOfDecimals";
const BuyERC20 = () => {
  const [amount, setAmount] = useState(0);
  const [priceWithFees, setPriceWithFees] = useState(0);
  const [purchaseState, setPurchaseState] = useState({
    isLoading: false,
    success: false,
    error: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const account = useRecoilValue(accountState);

  const [goldPrice, setGoldPrice] = useRecoilState(goldPriceState);

  const buyHandler = async () => {
    if (!account) return;
    if (+amount <= 0) return;

    setPurchaseState((prevState) => {
      return { ...prevState, isLoading: true, success: false, error: "" };
    });
    setMessage("");

    let provider;
    try {
      provider = new ethers.providers.Web3Provider(window.ethereum);
      //const provider = new ethers.providers.Web3Provider(connection)
      const signer = provider.getSigner(account);

      const swapTokenContract = new ethers.Contract(
        AppParams.SWAP_CONTRACT_ADDRESS,
        AppParams.SWAP_CONTRACT_ABI,
        signer
      );
      const price = await swapTokenContract.price();
      const priceDecimals = await swapTokenContract.priceDecimals();
      const platformFeesRate = await swapTokenContract.platformFeesRate();
      const feesDecimals = await swapTokenContract.platformFeesDecimals();

      const fees = new BigNumber(platformFeesRate.toString())
        .dividedBy(new BigNumber("10").pow(6))
        .multipliedBy(
          new BigNumber("10").pow(new BigNumber(feesDecimals.toString()))
        );

      const amountWei = new BigNumber(amount.toString()).multipliedBy(
        new BigNumber("10").pow(AppParams.PAYEMENT_TOKEN_DECIMALS)
      );
      const amountWithFees = calculateAmountWithFees(
        amountWei.toString(),
        fees,
        feesDecimals.toString()
      );
      const amountBNSC = amountWithFees
        .multipliedBy(price.toString())
        .dividedBy(
          new BigNumber("10").pow(new BigNumber(priceDecimals.toString()))
        );

      const tokenContract = new ethers.Contract(
        AppParams.PAYEMENT_TOKEN_ADDRESS,
        AppParams.PAYEMENT_TOKEN_ABI,
        signer
      );

      //chech allowance
      const allowed = await tokenContract.allowance(
        account,
        AppParams.SWAP_CONTRACT_ADDRESS
      );
      if (
        amountBNSC
          .dividedBy(
            new BigNumber("10").pow(
              new BigNumber(AppParams.PAYEMENT_TOKEN_DECIMALS.toString())
            )
          )
          .toNumber() >
        new BigNumber(allowed.toString())
          .dividedBy(
            new BigNumber("10").pow(
              new BigNumber(AppParams.PAYEMENT_TOKEN_DECIMALS.toString())
            )
          )
          .toNumber()
      ) {
        const tx = await tokenContract.approve(
          AppParams.SWAP_CONTRACT_ADDRESS,
          `0x${amountBNSC.toString(16)}`
        );

        await tx.wait();
      }
      const tx = await swapTokenContract.buyTokens(
        `0x${amountWei.toString(16)}`
      );
      await tx.wait();
      setPurchaseState((prevState) => {
        return { ...prevState, isLoading: false, success: true, error: "" };
      });
      setShowModal(true);
      setMessage(`You hace successfully bought ${amount} Ghold`);
    } catch (error) {
      setShowModal(false);
      setMessage("");
      setPurchaseState((prevState) => {
        return {
          ...prevState,
          isLoading: false,
          success: false,
          error: "Something went wrong, please try later",
        };
      });
      return;
    }

    try {
      const docRef = doc(db, "vault", "vault");
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      const { bullion1, bullion10, bullion20, bullion1000, bullion50 } =
        bullionRepartition(amount);
      await setDoc(docRef, {
        ...data,
        erc20Gold: data.erc20Gold + parseFloat(amount),
        bullion1: {
          ...data.bullion1,
          quantity: data.bullion1.quantity + bullion1,
        },
        bullion10: {
          ...data.bullion10,
          quantity: data.bullion10.quantity + bullion10,
        },
        bullion20: {
          ...data.bullion20,
          quantity: data.bullion20.quantity + bullion20,
        },
        bullion50: {
          ...data.bullion50,
          quantity: data.bullion50.quantity + bullion50,
        },
        bullion1000: {
          ...data.bullion1000,
          quantity: data.bullion1000.quantity + bullion1000,
        },
      });
    } catch (error) {
      console.log("Updating vault failed");
    }

    await provider.provider.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: AppParams.PLATFORM_TOKEN_ADDRESS,
          symbol: AppParams.PLATFORM_TOKEN_SYMBOL,
          decimals: AppParams.PLATFORM_TOKEN_DECIMALS,
          image: AppParams.PLATFORM_TOKEN_LOGO,
        },
      },
    });
  };

  useEffect(() => {
    if (amount) {
      const totalAmountBN = calculateAmountWithFees(
        amount,
        AppParams.PLATFORM_FEES_RATE,
        AppParams.PLATFORM_FEES_NUMBER_OF_DECIMALS
      );

      const amountInSC = totalAmountBN.multipliedBy(goldPrice.value);

      setPriceWithFees(amountInSC.toNumber());
    } else {
      setPriceWithFees(0);
    }
  }, [amount, goldPrice]);
  return (
    <>
      <div className="w-100 d-flex justify-content-center align-items-center mt-4">
        <h2>ERC20 GHOLD </h2>
      </div>
      <div className="VenteM container">
        <div className="BlocV">
          <h4>
            <a
              href={`https://goerli.etherscan.io/address/${AppParams.PLATFORM_TOKEN_ADDRESS}
          `}
              className="text-decortion-none text-white"
              rel="noreferrer"
              target={"_blank"}
            >
              Ghold to buy
            </a>{" "}
            <br />
            {goldPrice.success && (
              <small className="pc">{`(Unit Price $${parseFloat(
                goldPrice.value
              ).toFixed(fixNum(goldPrice.value))})`}</small>
            )}
          </h4>
          <div>
            {" "}
            <button
              className="btnM"
              onClick={() => {
                setAmount((prevState) => {
                  let newValue = parseInt(prevState) - 1;
                  if (newValue < 0) {
                    newValue = 0;
                  }
                  return newValue;
                });
              }}
            >
              <i className="fa-solid fa-caret-down"></i>
            </button>
            <input
              type="number"
              name="Price"
              value={amount}
              min={0}
              onChange={(e) => {
                const { value, min } = e.currentTarget;
                if (numberOfDecimals(value) > 4) {
                  return;
                }
                setAmount(value);
              }}
              onBlur={(e) => {
                const { value, min } = e.currentTarget;

                if (+value <= +min) {
                  setAmount(+min);
                }
              }}
            />
            <button
              className="btnM"
              onClick={() => {
                setAmount((prevState) => {
                  return parseInt(prevState) + 1;
                });
              }}
            >
              <i className="fa-solid fa-caret-up"></i>
            </button>
          </div>
          <div className="tex">
            <ul>
              <li>
                {`Platform fees are equals to ${AppParams.PLATFORM_FEES_RATE}%`}
              </li>
              <li>{`Price with fees is equal to $${priceWithFees.toFixed(
                fixNum(priceWithFees)
              )}`}</li>
            </ul>
          </div>
          <div>
            <button
              className="btn-primary buy"
              onClick={buyHandler}
              disabled={purchaseState.isLoading}
            >
              {purchaseState.isLoading ? (
                <span>
                  <span className="d-inline-block me-1">Pending</span>
                  <Spinner animation="border" size="sm" />
                </span>
              ) : (
                "Buy"
              )}
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <MessageModal
          onHide={() => {
            setShowModal(false);
            setMessage("");
          }}
          message={message}
          show={showModal}
        />
      )}
    </>
  );
};

export default BuyERC20;
