import React from "react";
import { Col, Row } from "react-bootstrap";
import classes from "./Wallet.module.css";
import BigNumber from "bignumber.js";
import { AppParams } from "../../config";
const HistoryItem = ({ amount, date, price }) => {
  return (
    <Row
      className={`${classes.item} d-flex justify-content-between my-1 rounded p-2 border-1 align-items-center w-100`}
    >
      <Col sm="12" md="2">
        <div className=" d-flex">
          <p className="d-md-none me-2">Date:</p>
          <p className={`tokenTa`}>{date}</p>
        </div>
      </Col>
      <Col sm="12" md="2">
        <div className="d-flex">
          <p className="me-2 d-md-none">Amount:</p>
          <p>{amount}</p>
        </div>
      </Col>
      <Col sm="12" md="2">
        <div className="d-flex">
          <p className="me-2 d-md-none">Price:</p>
          <p>
            {new BigNumber(amount.toString())
              .multipliedBy(new BigNumber(price.toString()))
              .toNumber()}
          </p>
        </div>
      </Col>
      <Col sm="2">
        <button className="btnConn p-1 px-2 ">
          <a
            href={`https://etherscan.io/address/${AppParams.PLATFORM_TOKEN_ADDRESS}
          `}
            className="text-decortion-none text-white"
            rel="noreferrer"
            target={"_blank"}
          >
            Etherscan Link
          </a>
        </button>
      </Col>
    </Row>
  );
};

export default HistoryItem;
