import BigNumber from "bignumber.js";
import React from "react";
import { Col } from "react-bootstrap";

const TotalNft = ({ bullions }) => {
  return (
    <>
      <Col sm="2"></Col>
      <Col sm="2"></Col>
      <Col sm="2">
        <div className="card p-1 d-flex justify-content-between align-items-center">
          <span className="p-1 d-inline-block  fw-bold">
            {`Total: ${(
              bullions.reduce((accu, curr) => {
                accu += new BigNumber(curr.quantity.toString())
                  .multipliedBy(parseFloat(curr.value).toString())
                  .toNumber();
                return accu;
              }, 0) / 1000
            )
              .toString()
              .slice(0, 5)} kg`}
          </span>
        </div>
      </Col>
      <Col sm="2"></Col>
    </>
  );
};

export default TotalNft;
