import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import axios from 'axios'

import { AppParams } from '../config'

const AssetCard = ({ nft }) => {

  const [item, setItem] = useState({
    tokenId: <hr size="5" color="#7373ab" width="40" />,
    image: ("/images/Spin-det.svg"),
    name: <hr size="5" color="#7373ab" width="70" />,
    description: <hr size="10" color="#7373ab" width="90" />
  })


  useEffect(() => {
    loadNFTs()
  }, [nft.tokenURI])

  async function loadNFTs() {

    const meta = await axios.get(nft.tokenURI.replace("ipfs://", AppParams.IPFS_GATWAY))
    let tokensMeta = {
      image: meta.data.image.replace("ipfs://", AppParams.IPFS_GATWAY),
      name: meta.data.name,
      description: meta.data.description,
    }
    setItem(tokensMeta)

  }

  function showOnExplorer(event) {
    event.stopPropagation();
    window.open(`${AppParams.BLOCK_EXPLORER_URL}token/${nft.contract.id}?a=${nft.tokenId}`, "_blanc")
  }

  return (
    <Card className='my-3 nftC'>
      <Card.Body>
        <div style={{ display: 'flex' }}>
          <div>
            <span className="CardTokenId" onClick={showOnExplorer}>#{nft.tokenId}</span>

          </div>
          <div className="CardTokenTokenClass">
            <div className='codeD'>{nft.tokenClass}</div>
          </div>
        </div>
        <div className="titleN" style={{ paddingTop: 10 }} >
          <Link to={`/my-assets/${nft.tokenId}/${nft.contract.id}`}>
            <Card.Title as='div'>{item.name}</Card.Title>
          </Link>
        </div>

        <Card.Text as='div'>{nft.description}</Card.Text>
        <Link to={`/my-assets/${nft.tokenId}/${nft.contract.id}`}>
          <Card.Img variant='top' src="/images/img-nft.png" />
        </Link>
        <div className='AlignBc'>
          <Card.Text className="CardTokenDescription">
            {item?.description}
          </Card.Text>
        </div>
      </Card.Body>
    </Card>
  )
}

export default AssetCard
