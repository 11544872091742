import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Nav,
  Button,
  DropdownButton,
  Dropdown,
  Modal,
} from "react-bootstrap";
import axios from "axios";

import { LinkContainer } from "react-router-bootstrap";
import useGetGoldPrice from "../Hooks/useGetGoldPrice";
import { shortenAddress } from "../utils/shortenAddress";

import { ethers } from "ethers";
import { AppParams, networks } from "../config";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  accountState,
  avaxValueUsd,
  goldPriceState,
  referralAddressState,
} from "../services/atoms";
import NotificationBar from "./NotificationBar";
import { useNavigate } from "react-router-dom";
import GoldPrice from "./header/GoldPrice";

const { ethereum } = window;

const changeNetwork = async ({ networkName, setError }) => {
  try {
    if (!ethereum) throw new Error("No crypto wallet found");
    await ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [
        {
          ...networks[networkName],
        },
      ],
    });
  } catch (err) {
    setError(err.message);
  }
};

const Header = () => {
  const [account, setAccount] = useRecoilState(accountState);
  const [referralAddress, setReferralAddress] =
    useRecoilState(referralAddressState);

  const [error, setError] = useState();

  const [isConnecting, setIsConnecting] = useState(false);
  const [isAvalanche, setIsAvalanche] = useState(false);
  const [provider, setProvider] = useState(ethereum);
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(true);
  const [isMetamaskAlertOpened, setIsMetamaskAlertOpened] = useState(false);

  const navigate = useNavigate();
  const { getGoldPrice } = useGetGoldPrice();

  useEffect(() => {
    getGoldPrice();
  }, [getGoldPrice]);

  const goldPrice = useRecoilValue(goldPriceState);
  const connectWallet = async () => {
    localStorage.setItem("loggedOut", "false");
    try {
      if (!ethereum) {
        setIsMetamaskAlertOpened(true);
        return;
        //return alert('Please install metamask')
      }

      // Loading...
      setIsConnecting(true);
      // Get all the accounts and the user will be able to choose one.
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      // Loading...
      setIsConnecting(false);
      setAccount(accounts[0]);
      // Test provider avalanche

      if (provider.chainId !== AppParams.AppChainId) {
        await handleNetworkSwitch(
          AppParams.AppChainId == "0xa86a" ? "etheurem" : "goerli"
        );
        // setIsAvalanche(false)
      } else {
        setIsAvalanche(true);
      }
      console.log("isAvalanche: ", isAvalanche);

      ethereum.on("accountsChanged", function (accounts) {
        setAccount(accounts[0]);
      });
    } catch (error) {
      console.log(error);
      setIsConnecting(false);
      throw new Error("No ethereum object.");
    }
  };

  const checkIfWalletIsConnected = async () => {
    try {
      //if (!ethereum) return alert('Please install metamask')

      const accounts = await ethereum.request({ method: "eth_accounts" });

      if (accounts.length) {
        setAccount(accounts[0]);
      } else {
        console.log("No Accounts found");
      }
    } catch (error) {
      console.log(error);
      throw new Error("No ethereum object.");
    }
  };

  // Control current Network via the API exposed by Metamask! (A nice user experience perspective)
  // If for example the user hasn't the BSC Mainnet in his wallet, then it will ask him
  // to add it inside his wallet
  const handleNetworkSwitch = async (networkName) => {
    setError();
    await changeNetwork({ networkName, setError });
  };
  const handleNetworkDisconnect = async () => {
    setAccount("");
    localStorage.setItem("loggedOut", "true");
    navigate("/");
  };

  const networkChanged = (chainId) => {
    window.location.reload();
    console.log({ chainId });
    // Test Avalanche Network
    // if (chainId === '0xa86a') {
    //   setIsAvalanche(true)
    // } else {
    //   setIsAvalanche(false)
    // }
  };

  const detectProvider = () => {
    let provider;
    if (window.ethereum) {
      provider = window.ethereum;
    } else if (window.web3) {
      provider = window.web3.currentProvider;
    } else {
      console.warn("No Ethereum browser detected! Check out Metamask");
    }
    //console.log('provider: ', provider.networkVersion)
    return provider;
  };

  const [avaxValue, setAvaxValue] = useRecoilState(avaxValueUsd);

  const fetchAvaxValue = async () => {
    axios.get("https://api.traderjoexyz.com/priceusd/avax").then((result) => {
      console.log("result.data: ", result.data);
      setAvaxValue(ethers.utils.formatEther(result.data + ""));
    });
  };

  useEffect(() => {
    setReferralAddress(localStorage.getItem("referralAddress"));

    // fetchAvaxValue
    fetchAvaxValue();
    // Detect Provider
    if (
      localStorage.getItem("loggedOut") == "true" ||
      localStorage.getItem("loggedOut") == null
    )
      return;

    setProvider(detectProvider());
    detectProvider();
    // If not avalanche network version
    // if (provider.networkVersion !== '43114') {
    // }
    connectWallet();

    if (
      (localStorage.getItem("loggedOut") == "true" ||
        localStorage.getItem("loggedOut") == null) &&
      window.location.pathname != "/"
    ) {
      navigate("/");
    }

    checkIfWalletIsConnected();
    try {
      ethereum.on("chainChanged", networkChanged);
    } catch (e) {
      console.error(e);
    }

    return () => {
      ethereum.removeListener("chainChanged", networkChanged);
    };
  }, []);

  // The second useEffect will run each time the provider is updated
  useEffect(() => {
    if (provider) {
      if (provider !== window.ethereum) {
        console.error(
          "Not window.ethereum provider. Do you have mutiple wallets installed ?"
        );
      }
      // If we have a provider that means that metamask is well installed
      setIsMetamaskInstalled(true);
    }
  }, [provider]);

  // useEffect(() => {
  //   checkIfWalletIsConnected()
  // }, [])

  return (
    <header>
      <Navbar variant="dark" expand="lg" collapseOnSelect className="pt-3 pb-3">
        <Container
          fluid
          // fluid
        >
          <LinkContainer to="/">
            <Navbar.Brand className="alogo">
              <span className="logoH">
                GHOLD <br />
                <span className="smallL">BY RONPIWI</span>
              </span>
            </Navbar.Brand>
          </LinkContainer>
          <div className="element-hide-l">
            <NotificationBar></NotificationBar>
          </div>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              {/* <LinkContainer to='/'>
                <Nav.Link>Dashboard</Nav.Link>
              </LinkContainer>  */}

              {/* {!isMetamaskInstalled && (
                  <LinkContainer to='https://metamask.io/download/'>
                    <Nav.Link>Install Metamask</Nav.Link>
                  </LinkContainer>
                )} */}
              <LinkContainer to="/">
                <Nav.Link>Marketplace</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/midas_touch">
                <Nav.Link>Midas Touch</Nav.Link>
              </LinkContainer>
              {/* <LinkContainer to='/create-item'>
                <Nav.Link>Sell Digital Asset</Nav.Link>
              </LinkContainer> */}

              {account && (
                <LinkContainer to="/wallet">
                  <Nav.Link>My wallet</Nav.Link>
                </LinkContainer>
              )}
              <LinkContainer to="/vault">
                <Nav.Link>Vault</Nav.Link>
              </LinkContainer>
            </Nav>
            {goldPrice.success && <GoldPrice price={goldPrice.value} />}
            {!account ? (
              <button
                type="button"
                className="btn btn-primary btnConn"
                onClick={connectWallet}
              >
                {!isConnecting && "Wallet Connect"}
                {isConnecting && "Loading..."}
              </button>
            ) : (
              // <button type='button' className='btn btn-primary'>
              //   {shortenAddress(account)}
              // </button>
              <div style={{ display: "flex" }}>
                <div className="hideMobile">
                  <NotificationBar></NotificationBar>
                </div>
                {/*
                <Button
                  id='dropdown-basic-button'
                  title={shortenAddress(account)}
                  className='padd'
                  variant='primary'
                >
                  {shortenAddress(account)}
                </Button>
            */}
                <DropdownButton
                  id="dropdown-basic-button"
                  title={shortenAddress(account)}
                  className="padd"
                >
                  <Dropdown.Item onClick={() => handleNetworkDisconnect()}>
                    Log out
                  </Dropdown.Item>
                </DropdownButton>
                {/* <DropdownButton
                  id='dropdown-basic-button'
                  title={shortenAddress(account)}
                  className='padd'
                >
                  <Dropdown.Item
                    onClick={() => handleNetworkSwitch('avalanche')}
                  >
                    Switch To Avalanche
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleNetworkDisconnect('avalanche')}
                  >
                    Disconnect
                  </Dropdown.Item> */}
                {/* <Dropdown.Item
                  href='#/action-1'
                  onClick={() => handleNetworkSwitch('polygon')}
                >
                  Switch To Polygon
                </Dropdown.Item>
                <Dropdown.Item
                  href='#/action-2'
                  onClick={() => handleNetworkSwitch('bsc')}
                >
                  Switch To BSC
                </Dropdown.Item> */}
                {/* </DropdownButton> */}
              </div>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Modal
        className="Imetamask"
        show={isMetamaskAlertOpened}
        onHide={(e) => {
          setIsMetamaskAlertOpened(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          To manage your tokens, please use Metamask Wallet
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setIsMetamaskAlertOpened(false);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </header>
  );
};

export default Header;
