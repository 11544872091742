// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";

import { getStorage } from "firebase/storage";
import { AppParams } from "../config";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// Initialize Firebase
const app = initializeApp(AppParams.FIREBASE_SETTINGS);

// init services
const db = getFirestore();

const projectStorage = getStorage();

export { db, projectStorage };
